import React, { useRef, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as d3 from 'd3';
import { ADDITIONAL_STATUS_DESCRIPTION, COLORS, MAPPED_STATUS_AND_COLORS, RED, YELLOW } from '../../utils/heatmap';
import { Tooltip, Icon } from '@chakra-ui/react';
import { GoAlert } from "react-icons/go";
import { getIsMobile } from '../../utils/device';
import "./heatmap.css";

const calculateGrid = (dataLength, isMobile) => {

  const cols = isMobile && dataLength > 3 ? 3 : Math.ceil(Math.sqrt(dataLength)); // Número de columnas
  const rows = Math.ceil(dataLength / cols);    // Número de filas
  return { cols, rows };
}

const getFirstText = (device) => device.active_service?.type === 'service'
  ? device.active_service?.name
  : device.serial || "";

const getSecondText = (device) => device?.active_service && device?.active_service?.operatorAccount
  ? device?.active_service?.operatorAccount?.name
  : device?.active_service?.accountCreator?.name || ""

const getAlertIcon = (color, width, height) =>
  <Icon as={GoAlert} style={{ color, width, height }}/>;

const hasToBlink = (device) => {
  const someAlarmWithoutSeen = device.alarms.some(alarm => !alarm.assistant_device_seen);
  return [RED, YELLOW].includes(device.device_status_color) && someAlarmWithoutSeen;
}

const darkenColor = (color) => {
  const darkenFactor = 0.4; // Ajusta este valor para cambiar la intensidad del color oscuro
  const d3Color = d3.color(color);
  if (!d3Color) {
    return color; // Retorna el color original si no se puede calcular el color oscuro
  }
  const r = d3Color.r;
  const g = d3Color.g;
  const b = d3Color.b;
  return `rgb(${Math.floor(r * (1 - darkenFactor))}, ${Math.floor(g * (1 - darkenFactor))}, ${Math.floor(b * (1 - darkenFactor))})`;
};

const Heatmap = ({ deviceData = [], onSelect, sortVertically = true }) => {
  const svgRef = useRef();
  const [isSvgRendered, setIsSvgRendered] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const isMobile = getIsMobile(navigator, window);

  useEffect(() => {
    d3.select(svgRef.current).selectAll('*').remove();
    // aux variables
    const margin = { top: 10, right: 10, bottom: 10, left: 10 };
    const width = window.innerWidth
    const height = isMobile ? window.innerHeight - 40 : window.innerHeight
    const spacing = 0.5;
    const { cols, rows } = calculateGrid(deviceData.length, isMobile);
    const rectangleWidth = (width - (cols - 1) * spacing) / cols;
    const rectangleHeight = (height - (rows - 1) * spacing) / rows;

    // SVG to render
    const svg = d3.select(svgRef.current)
      .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height}`)
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Custom Tooltip
    // const renderTooltipContent = (d) => (
    //   <div style={{ background: 'black '}}>
    //     <div style={{ display: 'flex', alignItems: 'center' }}>
    //       {/* Icono opcional */}
    //       <span role="img" aria-label="icon" style={{ marginRight: '8px' }}>🔧</span>
    //       <strong>{d.device_id}</strong>
    //     </div>
    //     <p>Location: {d.location}</p>
    //     <p>Status: {d.device_status}</p>
    //     {/* Iconos adicionales, texto o cualquier otro contenido que quieras agregar */}
    //   </div>
    // );

    // Colors
    const colorScale = d3
      .scaleLinear()
      .domain([0, 1, 2, 3, 4, 5])
      .range(COLORS);

    // Rectangles and Texts
    const groups = svg.selectAll('.group')
      .data(deviceData)
      .enter()
      .append('g')
      .attr('class', 'group')
      .attr("transform", (d, i) => {
        const x = !sortVertically ? (i % cols) * (rectangleWidth + spacing) : Math.floor(i / rows) * (rectangleWidth + spacing);
        const y = !sortVertically ? Math.floor(i / cols) * (rectangleHeight + spacing) : (i % rows) * (rectangleHeight + spacing);
        return `translate(${x}, ${y})`;
      })
      .on('click', (_, d) => {
        onSelect(d.serial);
      })
      .on('mouseover', function(event, d) {
        if (!isSvgRendered) return;
        d3.select(this).select('rect').transition()
          .duration('50')
          .style('fill', "rgb(167,211,241)");
      })
      .on('mouseout', function(_, d) {
        if (!isSvgRendered) return;
        d3.select(this).select('rect').transition()
          .duration('50')
          .style('fill', d => colorScale(MAPPED_STATUS_AND_COLORS[d.device_status_color]));
      })
      .style('cursor', 'pointer');

    groups.append('rect')
      .attr('class', 'rect')
      .attr('width', rectangleWidth)
      .attr('height', rectangleHeight)
      .attr('rx', cols > 10 ? 4 : 10)
      .attr('ry', cols > 10 ? 4 : 10)
      .style('--originalColor', d => colorScale(MAPPED_STATUS_AND_COLORS[d.device_status_color]))
      .style('--darkColor', d => darkenColor(colorScale(MAPPED_STATUS_AND_COLORS[d.device_status_color])))
      .style('fill', d => colorScale(0))
      .transition()
      .duration(1000)
      .style('fill', d => colorScale(MAPPED_STATUS_AND_COLORS[d.device_status_color]));
      
    // Add icons by status
    groups.each(function(d) {
      if (d.additional_statuses.length) {
        const width = rectangleWidth * 0.2;
        const height = rectangleHeight * 0.2;
        const xPosition = rectangleWidth < 120 ? rectangleWidth * 0.7 : rectangleWidth * 0.8;
        const iconHTML = ReactDOMServer.renderToStaticMarkup(getAlertIcon('white', width, height));
        d3.select(this)
          .append('foreignObject')
          .attr('width', width)
          .attr('height', height)
          .attr('x', xPosition) // Posicionarlo en la esquina superior derecha
          .attr('y', isMobile ? rectangleHeight * 0.1 : 5) // Ajustar la posición vertical
          .style('overflow', 'visible')
          .on('mouseover', function(event, d) {
            if (!isSvgRendered) return;
            d3.select(this).select('rect').transition()
              .duration('50')
              .style('fill', "rgb(167,211,241)");
    
            // // Actualizamos la tooltip
            setTooltipContent(
              d.additional_statuses.map(status=> ADDITIONAL_STATUS_DESCRIPTION[status]).join('\n')
            );
            setTooltipPosition({ x: event.pageX, y: event.pageY });
            setTooltipVisible(true);
          })
          .on('mouseout', function(_, d) {
            if (!isSvgRendered) return;
            d3.select(this).select('rect').transition()
              .duration('50')
              .style('fill', d => colorScale(MAPPED_STATUS_AND_COLORS[d.device_status_color]));
            
            // // Ocultar tooltip
            setTooltipVisible(false);
          })
          .html(`<div xmlns="http://www.w3.org/1999/xhtml">${iconHTML}</div>`);
      }
    });

    groups.append('text')
      .attr("x", rectangleWidth / 2)
      .attr("y", isMobile ? rectangleHeight / 1.5 : rectangleHeight / 2)
      .attr('dy', '-0.5em')
      .attr('text-anchor', 'middle')
      .style('fill', 'white')
      .style('font-size', isMobile ? '10px' : cols < 10 ? '14px' : '10px')
      .attr("font-weight", 700)
      .text(d => cols > 6 && getFirstText(d).length > 12 ? `${getFirstText(d).substring(0, 12)}...` : `${getFirstText(d)}`)
      .append('tspan')
      .attr('x', rectangleWidth / 2)
      .attr('dy', '1em')
      .text(d => isMobile ? '' : cols > 8 && getSecondText(d).length > 20 ? `${getSecondText(d).substring(0, 10)}...` : `${getSecondText(d)}`);

    // Asignar clases 'blinking' a los rectángulos necesarios
    deviceData.forEach((d, i) => {
      if (hasToBlink(d)) {
        const rect = d3.select(groups.nodes()[i]).select('.rect');
        rect.classed('blinking', true);
      }
    });
    setIsSvgRendered(true);
  }, [deviceData, sortVertically]);

  return (
    <div>
      <div style={{ width: '100%', height: '96vh' }}>
        <svg width="100%" height="100%" ref={svgRef}></svg>
        {tooltipVisible && (
        <Tooltip
          label={
            <span dangerouslySetInnerHTML={{ __html: tooltipContent.replace(/\n/g, '<br />') }} />
          }
          isOpen={tooltipVisible}
          placement='bottom-start'
          hasArrow
          arrowSize={6}
        >
          <div style={{
            position: 'absolute',
            left: tooltipPosition.x,
            top: tooltipPosition.y,
            pointerEvents: 'none'
          }} />
        </Tooltip>
      )}
      </div>
    </div>
  );
};

export default Heatmap;
