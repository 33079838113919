import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { getProtectedResource } from '../auth/auth-fetch'

export const useEvents = () => {
  const { user, getAccessTokenSilently } = useAuth0()
  const [events, setEvents] = useState([])

  const URL_BACK = process.env.REACT_APP_URL_BACK
  const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE

  const fetchEvents = async ({ pageParam = 1, queryKey }) => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: AUDIENCE,
        },
        cacheMode: "on"
      })
      let urlWithFilters = `${URL_BACK}/api/events-filter/${user?.sub}?page=${pageParam}`

      if (queryKey && queryKey.length > 0) {
        const { camFilter, dateFilter, plateFilter, tagFilter } = queryKey[0];
        if (camFilter && camFilter.length > 0) {
          const camerasParam = camFilter.map(cam => `&camara=${cam}`).join('');
          urlWithFilters += camerasParam;
        }
        if (dateFilter) {
          if (dateFilter.startDate && dateFilter.endDate) {
            urlWithFilters += `&startDate=${dateFilter.startDate}&endDate=${dateFilter.endDate}`;
          } else if (dateFilter.startDate) {
            urlWithFilters += `&startDate=${dateFilter.startDate}`;
          } else if (dateFilter.endDate) {
            urlWithFilters += `&endDate=${dateFilter.endDate}`;
          }
        }
        if (plateFilter) {
          urlWithFilters += `&plate=${plateFilter}`;
        }
        if (tagFilter) {
          urlWithFilters += `&tag=${tagFilter}`;
        }
      }
      const { data } = await getProtectedResource(urlWithFilters, token)
      setEvents(data.data)
      const nextPage = Number(data.currentPage) + 1
      return {
        events: data.data,
        currentPage: data.currentPage,
        lastPage: data.totalPages,
        nextPage,
        filters: data.filters
      }
    } catch (error) {
      console.error(error);
      throw error.response.data.error;
    }

  }

  return { events, setEvents, fetchEvents }
}
