const Update = ({classNames}) => (
  <svg className={classNames} viewBox="0 0 17 17">
    <path
      fill="#fff"
      fillOpacity={0.64}
      fillRule="evenodd"
      d="M6.114 2.875a6.094 6.094 0 0 1 2.647-.602v.5-.5c3.367 0 6.1 2.725 6.1 6.088v.93a.5.5 0 1 1-1 0v-.93a5.094 5.094 0 0 0-5.1-5.088H8.76A5.094 5.094 0 0 0 4.772 5.19a.5.5 0 1 1-.782-.623 6.094 6.094 0 0 1 2.124-1.692ZM3.177 6.94a.5.5 0 0 1 .5.5v.931a5.092 5.092 0 0 0 5.1 5.089 5.139 5.139 0 0 0 3.986-1.906.5.5 0 1 1 .778.63 6.138 6.138 0 0 1-4.763 2.276h-.001a6.092 6.092 0 0 1-6.1-6.089v-.931a.5.5 0 0 1 .5-.5Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillOpacity={0.64}
      fillRule="evenodd"
      d="M2.799 6.639a.5.5 0 0 1 .699-.008l1.437 1.375a.5.5 0 1 1-.691.723L3.16 7.692 2.13 8.72a.5.5 0 1 1-.706-.707l1.375-1.375Zm9.804 1.383a.5.5 0 0 1 .707-.016l1.084 1.037 1.03-1.03a.5.5 0 0 1 .707.708l-1.375 1.375a.5.5 0 0 1-.7.008L12.62 8.729a.5.5 0 0 1-.016-.707Z"
      clipRule="evenodd"
    />
  </svg>
)
export default Update
