// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  list-style: none;
  padding: 0;
}

.pagination li {
  display: inline-block;
  margin: 0 5px;
}

.pagination li a {
  padding: 8px 12px;
  border: 1px solid #ddd;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

.pagination li a:hover {
  background-color: #007bff;
  color: white;
}

.pagination li a.disabled {
  cursor: not-allowed;
  color: #ddd;
}

.pagination li a.active {
  background-color: #007bff;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/heatmap/table.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,cAAc;EACd,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd","sourcesContent":["/* styles.css */\r\n.pagination {\r\n  display: flex;\r\n  justify-content: center;\r\n  margin-top: 20px;\r\n  list-style: none;\r\n  padding: 0;\r\n}\r\n\r\n.pagination li {\r\n  display: inline-block;\r\n  margin: 0 5px;\r\n}\r\n\r\n.pagination li a {\r\n  padding: 8px 12px;\r\n  border: 1px solid #ddd;\r\n  color: #007bff;\r\n  text-decoration: none;\r\n  cursor: pointer;\r\n}\r\n\r\n.pagination li a:hover {\r\n  background-color: #007bff;\r\n  color: white;\r\n}\r\n\r\n.pagination li a.disabled {\r\n  cursor: not-allowed;\r\n  color: #ddd;\r\n}\r\n\r\n.pagination li a.active {\r\n  background-color: #007bff;\r\n  color: white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
