export const Circle = ({ color }) => {
  return (
    <div
      className="circle"
      style={{
        backgroundColor: color,
        height: 14,
        width: 14
      }}
    ></div>
  )
}
