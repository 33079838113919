import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getProtectedResource } from '../auth/auth-fetch';

export const useCamBattery = (deviceid = "1") => {
    const { user, getAccessTokenSilently } = useAuth0();
    const [batterydata, setBatterydata] = useState([]);
    const [batteryStatus, setBatteryStatus] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingStatus, setIsLoadingStatus] = useState(true)
    const [error, setError] = useState(false)
    const [errorStatus, setErrorStatus] = useState(false)
    const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
    const URL_BACK = process.env.REACT_APP_URL_BACK;

    useEffect(() => {
        const fetchBattery = async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: AUDIENCE,
                    },
                });
                const urlFetch = `${URL_BACK}/api/battery/${deviceid}?auth=${user?.sub}`;
                const { data } = await getProtectedResource(urlFetch, token);
                setBatterydata(data);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setError(error.response.data.error)
            }
        };
        fetchBattery();

        const interval = setInterval(() => {
            fetchBattery();
        }, 60000);

        return () => clearInterval(interval);

    }, [AUDIENCE, URL_BACK, deviceid, getAccessTokenSilently, user?.sub]);

    useEffect(() => {
        const fetchBatteryStatus = async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: AUDIENCE,
                    },
                });
                const urlFetch = `${URL_BACK}/api/battery-status/${deviceid}?auth=${user?.sub}`;
                const { data } = await getProtectedResource(urlFetch, token);
                setBatteryStatus(data);
                setIsLoadingStatus(false);
            } catch (error) {
                setErrorStatus(error.response.data.error)
                console.error(error);
            }
        };
        fetchBatteryStatus();
    }, [AUDIENCE, URL_BACK, deviceid, getAccessTokenSilently, user?.sub]);

    return { batterydata, setBatterydata, isLoading, setIsLoading, batteryStatus, isLoadingStatus, error, errorStatus }
}
