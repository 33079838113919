import { useAuth0 } from '@auth0/auth0-react';
import { postProtectedResource } from '../auth/auth-fetch';
import { useState } from 'react';

export const useAlarms = () => {
  const { user, isLoading, getAccessTokenSilently } = useAuth0()
  const [shouldPlaySound, setShouldPlaySound] = useState(false);
  const [messages, setMessages] = useState([{}])
  const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
  const URL_BACK = process.env.REACT_APP_URL_BACK;

  const setAlarmsSeen = async (props) => {
    if (!isLoading) {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: AUDIENCE,
          },
        })
        const urlFetch = `${URL_BACK}/api/devices-assistance-alarm`
        const body = {
          idAuth0: user?.sub,
          deviceID: props,
        }
        await postProtectedResource(urlFetch, token, body)
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleShowError = (type, title, error, deviceId) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { type, title, error, show: true },
    ]);
    setAlarmsSeen(deviceId);

    // Configurar un temporizador para cambiar show a false después de (5 segundos)
    setTimeout(() => {
      setMessages((prevMessages) =>
        prevMessages.map((message) => ({ ...message, show: false }))
      );
    }, 5000);
    setShouldPlaySound(true);
  };

  // Función que se llama cuando se reproduce el sonido
  const handleSoundPlayed = () => {
    // Aquí puedes realizar acciones adicionales o actualizar el estado según sea necesario
    setShouldPlaySound(false); // Puedes actualizar el estado aquí o realizar otras acciones
  };

  return { setAlarmsSeen, handleShowError, handleSoundPlayed, shouldPlaySound, messages };
};