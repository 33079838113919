import * as React from "react"
const Download = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={23}
    viewBox="0 0 25 26"
    {...props}
  >
    <path
      d="M6.64 9.914c-.312 0-.609.121-.827.336a1.14 1.14 0 0 0-.344.809V21c0 .305.125.598.343.813.22.214.516.335.829.335h11.718c.313 0 .61-.12.828-.335.22-.215.344-.508.344-.813v-9.941c0-.301-.125-.594-.343-.809a1.18 1.18 0 0 0-.829-.336h-1.953a.775.775 0 0 1-.781-.766c0-.421.352-.765.781-.765h1.953c.727 0 1.422.281 1.934.785.512.5.8 1.184.8 1.89V21c0 .71-.288 1.39-.8 1.895a2.77 2.77 0 0 1-1.934.785H6.641a2.77 2.77 0 0 1-1.934-.785 2.658 2.658 0 0 1-.8-1.895v-9.941c0-.707.288-1.391.8-1.891a2.753 2.753 0 0 1 1.934-.785h1.953c.43 0 .781.344.781.765a.775.775 0 0 1-.781.766Zm0 0"
      style={{
        stroke: "none",
        fillRule: "evenodd",
        fill: "#fff",
        fillOpacity: 1,
      }}
    />
    <path
      d="M12.5 2.266c.43 0 .781.343.781.765v12.684l2.574-2.52a.793.793 0 0 1 1.102 0c.309.301.309.785 0 1.082l-3.906 3.825a.793.793 0 0 1-1.102 0l-3.906-3.825a.748.748 0 0 1 0-1.082.793.793 0 0 1 1.102 0l2.574 2.52V3.03c0-.422.351-.765.781-.765Zm0 0"
      style={{
        stroke: "none",
        fillRule: "evenodd",
        fill: "#fff",
        fillOpacity: 1,
      }}
    />
  </svg>
)
export default Download
