import {
  HStack,
  Table,
  Thead,
  Th,
  Box,
  Text,
  Tr,
  Tbody,
  Td,
  Stack,
  Icon,
  IconButton
} from '@chakra-ui/react'
import { Circle } from '../Iconsjsx/Circle'
import { DetailPopover } from './DetailPopover'
import { RED, YELLOW } from '../../utils/heatmap';
import { useState, useEffect } from 'react';
import ReactPaginate from "react-paginate";
import './table.css';
import { FaRegEye, FaCheckCircle } from "react-icons/fa";
import { postProtectedResource } from '../../auth/auth-fetch'
import { useAuth0 } from '@auth0/auth0-react'
import { usePermissionState } from '../../context/PermissionContext'
import { PERMISSION_SEEN } from '../../utils/heatmap'
import { useHeatmapContext } from '../../context/HeatmapContext'

const AlarmTable = ({ alarmsData, selectedDevice, updateData, showPagination = true, pagesSize = 5 }) => {
  const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE
  const URL_BACK = process.env.REACT_APP_URL_BACK
  const [originalData] = useState(alarmsData.sort((a, b) => b.severity-a.severity));
  const [filteredData, setFilteredData] = useState(alarmsData.sort((a, b) => b.severity-a.severity));
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const { fetchDeviceData } = useHeatmapContext();

  const { user, getAccessTokenSilently } = useAuth0()
  const idAuth0 = user?.sub;
  const permissions = usePermissionState();
  const permissionSeen = !!permissions?.userData?.find(
    permission => permission.permission_name === PERMISSION_SEEN
  );

  useEffect(() => {
    const endOffset = itemOffset + pagesSize;
    setFilteredData(originalData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(originalData.length / pagesSize));
  }, [itemOffset, pagesSize]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = event.selected * pagesSize % originalData.length;
    setItemOffset(newOffset);
  };

  const handleSeen = async (alarmId) => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: AUDIENCE,
        },
      })
      const urlFetch = `${URL_BACK}/api/devices-assistance-seen`
      const body = {
        idAuth0,
        alarm_id: alarmId,
        serial: selectedDevice.serial,
      }
      const response = await postProtectedResource(urlFetch, token, body)
      setFilteredData((prevItems) => 
        prevItems.map(item =>
          item.id === alarmId 
            ? { ...item, assistant_device_seen: response.data }
            : item
        )
      );
      fetchDeviceData();
    } catch (error) {
      console.error(error)
    }
  }

  const calculateDateDifference = (date) => {
    const date1Obj = new Date(date);
    const date2Obj = new Date();
    const difference = date2Obj - date1Obj;
    const daysDifference = Math.floor(difference / (1000 * 60 * 60 * 24));

    if (daysDifference < 3) {
      // Calculate hours, minutes, and seconds
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);

      // Format the time string
      // For only minutes
      if (hours === 0) {
        return `${minutes} minutes ago`;
      }
      // For hours
      return `${hours} hs ago`;
    } else {
      return `${daysDifference} days ago`;
    }
  };

  return (
    <Box maxWidth="800px" overflowX="auto">
      <Table
        overflow="scroll"
        variant="unstyled"
        bg="white"
        colorScheme="table"
        color="#4A5568"
        fontWeight="600"
        marginY="15px"
        size="sm"
      >
        <Thead borderBottom="2px" borderColor="gray.100">
          <Tr className="table-head">
            <Th
              size="sm"
              fontSize={'14px'}
              paddingX="10px"
              paddingY="15px"
              textAlign="center"
              key="detail"
              allowsorting="true"
              borderRight={'1px solid #E2E8F0'}
              cursor="pointer"
            >
              <Box
                as="div"
                display="flex"
                flexDirection="row"
                textAlign="center"
                justifyContent="center"
                alignItems="center"
              >
                Detail
              </Box>
            </Th>
            <Th
              size="sm"
              fontSize={'14px'}
              paddingX="5px"
              paddingY="15px"
              textAlign="center"
              key="duration"
              allowsorting="true"
              borderRight={'1px solid #E2E8F0'}
              cursor="pointer"
            >
              <Box
                as="div"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                Duration
              </Box>
            </Th>
            <Th
              size="md"
              fontSize={'14px'}
              paddingX="5px"
              paddingY="15px"
              textAlign="center"
              key="initialDate"
              allowsorting="true"
              borderRight={'1px solid #E2E8F0'}
              cursor="pointer"
            >
              <Box
                as="div"
                display="flex"
                textAlign="center"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                Initial Date
              </Box>
            </Th>
            <Th
              size="sm"
              fontSize={'14px'}
              paddingX="5px"
              paddingY="15px"
              textAlign="center"
              key="source"
              allowsorting="true"
              borderLeft={'1px solid #E2E8F0'}
              cursor="pointer"
            >
              <Box
                as="div"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                Source
              </Box>
            </Th>
            <Th
              size="sm"
              fontSize={'14px'}
              paddingX="5px"
              paddingY="15px"
              textAlign="center"
              key="seen"
              allowsorting="true"
              borderLeft={'1px solid #E2E8F0'}
              cursor="pointer"
            >
              <Box
                as="div"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                Seen
              </Box>
            </Th>
            <Th
              size="md"
              fontSize={'14px'}
              paddingX="5px"
              paddingY="15px"
              textAlign="center"
              key="viewer"
              allowsorting="true"
              borderLeft={'1px solid #E2E8F0'}
              cursor="pointer"
            >
              <Box
                as="div"

                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                Viewer
              </Box>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredData && filteredData.map((alarm, index) => {
              return (
                <Tr key={index} borderTop="1px solid #E2E8F0" className="event-table">
                  <Td textAlign="center" borderLeft="1px solid #E2E8F0" px={0} dir>
                    {/* DETAIL */}
                    <Stack direction={"row"} ml={"5%"} spacing={6} alignItems={"flex-start"}>
                  {alarm.alarm_rule?.severity === 1 ?
                        <Circle color={RED}></Circle> : alarm.alarm_rule.severity === 2 ?
                          <Circle color={YELLOW}></Circle> : <Circle color={YELLOW}></Circle>}
                  <DetailPopover alarm={alarm.alarm_rule} />
                  </Stack>
                  </Td>
                  {/* DURATION */}
                  <Td textAlign="center" borderLeft="1px solid #E2E8F0" px={0}>
                    <Text m={0} p={0} fontSize={'13px'}>
                      {calculateDateDifference(alarm.createdAt)}
                    </Text>
                  </Td>
                  {/* INITIAL DATE */}
                  <Td
                    textAlign="center"
                    borderLeft="1px solid #E2E8F0"
                    px={0}
                  >
                    <Text
                      m={0}
                      p={0}
                      fontSize={'13px'}
                    >
                      {alarm.createdAt.substring(
                        0,
                        10
                      )}
                    </Text>
                  </Td>
                  {/* ORIGIN */}
                  <Td
                    textAlign="center"
                    borderLeft="1px solid #E2E8F0"
                    px={0}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Box bg="#BEE3F8" fontSize="13px" borderRadius="8px" textAlign="center" width="fit-content" padding="5px" color="#2A4365">
                      {alarm.alarm_rule.id <= 2000 && alarm.alarm_rule.id >= 1000 ? 'Assistant' : 'mSuite'}
                    </Box>

                  </Td>
                  {/* SEEN */}
                  <Td textAlign="center" borderLeft="1px solid #E2E8F0" px={0}>
                    {alarm?.assistant_device_seen
                      ? (
                        <Icon as={FaCheckCircle} boxSize={5} color='green.500'/>
                      ) : permissionSeen ? (
                          <IconButton
                            onClick={() => handleSeen(alarm.id)}
                            variant='transparent'
                            aria-label='Done'
                            color='blue.500'
                            icon={<FaRegEye />}
                          />
                        ) : (
                          <Icon as={FaRegEye} boxSize={5} color='blue.500' />
                        )
                    }
                  </Td>
                  {/* VIEWER */}
                  <Td textAlign="center" borderLeft="1px solid #E2E8F0" px={0}>
                    <Text m={0} p={0} fontSize={'13px'}>
                      {alarm?.assistant_device_seen?.user_information || '-'}
                    </Text>
                  </Td>
                </Tr>
              )
            })}
        </Tbody>
      </Table>
      {showPagination && pageCount > 1 && (
        <HStack
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< "
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          activeClassName="active"
        />
      </HStack>
      )}
    </Box>
  );
}

export default AlarmTable;