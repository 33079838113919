import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { getProtectedResource } from '../auth/auth-fetch'

export const useData = () => {
  const { user, getAccessTokenSilently } = useAuth0()
  const [dataLogs, setDataLogs] = useState(null)
  const [dataAlarms, setDataAlarms] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [selectedDevice, setSelectedDevice] = useState('');
  const [optionsDevice, setOptionsDevice] = useState('');

  const [selectedModel, setSelectedModel] = useState('');
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedServCompany, setSelectedServCompany] = useState('');

  const [optionsModels, setOptionsModels] = useState('');
  const [optionsLocations, setOptionsLocations] = useState('');
  const [optionsServCompany, setOptionsServCompany] = useState('');

  const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE
  const URL_BACK = process.env.REACT_APP_URL_BACK

  const fetchLogs = async (query) => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: AUDIENCE,
        }
      })

      const { pagination,columnFilters, sorting } = query;
      const fetchURL = new URL(`${URL_BACK}/api/log-filters/${user?.sub}`);
      fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);

      // Filters and Sorts
      columnFilters.length && fetchURL.searchParams.set('filters', JSON.stringify(columnFilters));
      sorting.length && fetchURL.searchParams.set('sorting', JSON.stringify(sorting));

      const { data } = await getProtectedResource(fetchURL, token)

      const nextPage = Number(data.currentPage) + 1

      setDataLogs({
        events: data.data,
        currentPage: data.currentPage,
        lastPage: data.totalPages,
        nextPage,
        filters: data.filters,
        allLogs: data.allLogs
      })

      setOptionsDevice(data.filters.uniqueDevices)
      setOptionsModels(data.filters.uniqueModels)
      setOptionsLocations(data.filters.uniqueLocations)
      setOptionsServCompany(data.filters.uniqueServiceCompany)
      setIsLoading(false);
      return {
        events: data.data,
        currentPage: data.currentPage,
        lastPage: data.totalPages,
        totalEvents: data.totalEvents,
        nextPage,
        filters: data.filters, 
        allLogs: data.allLogs,
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchAlarms = async ({ queryKey }) => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: AUDIENCE,
        }
      })

      const urlFetch = `${URL_BACK}/api/rules-assistance/${user?.sub}?page=${queryKey[2].current}`

      const { data } = await getProtectedResource(urlFetch, token)
      setDataAlarms(data)
      setIsLoading(false)
      return {
        events: data.data,
        currentPage: data.currentPage || 1,
        lastPage: data.totalPages || 24,
        nextPage: data.currentPage && data.currentPage +1,
        filters: data.filters
      }
    } catch (error) {
      console.error('Error fetching Log:', error)
    }
  }
  return {
    dataAlarms,
    dataLogs,
    isLoading,
    fetchLogs,
    fetchAlarms,
    setOptionsDevice,
    setSelectedDevice,
    selectedDevice,
    optionsDevice,
    selectedModel,
    selectedClient,
    setSelectedClient,
    setSelectedModel,
    selectedServCompany,
    setSelectedServCompany,
    optionsModels,
    setOptionsModels,
    optionsServCompany,
    setOptionsServCompany,
    optionsLocations,
    setOptionsLocations,
  }
}
