import * as React from "react"
const SvgEye = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M.678 7.443C2.712 4.528 5.348 3 7.99 3c3.102 0 5.775 2.02 7.351 4.456a1.005 1.005 0 0 1 .001 1.087C13.766 11.01 11.111 13 7.99 13 4.834 13 2.231 11.014.66 8.554a1 1 0 0 1 .018-1.109l.001-.002Zm.822.57.001.002C2.953 10.287 5.28 12 7.99 12c2.68 0 5.057-1.72 6.51-3.996v-.001L14.502 8l-.001-.002C13.04 5.746 10.646 4 7.99 4 5.76 4 3.4 5.29 1.5 8.013Z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M8 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM5 8a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgEye