import React from "react";
import img1 from '../../images/analytics-svgrepo-com.svg';

const HeroHome = () => (
    <div className="text-center hero">
      <img src={img1} width="250" height="250" alt="" />
      <p className="lead" style={{ marginTop: "8%"}}>
        You can now see your devices
      </p>
    </div>
);

export default HeroHome;
