import 'bootstrap/dist/css/bootstrap.min.css'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import TreeMap from '../components/treemap/TreeMap'
import { Box, Container } from '@chakra-ui/react'
import Loading2 from '../components/misc/loading-2'
import { usePermissionState } from '../context/PermissionContext'

const Devices = () => {
  const { userPermission, isLoading } = usePermissionState()
 
  if (isLoading || userPermission === null) {
    return (
      <Container display="flex" alignItems="center" justifyContent="center" height="100vh">
        <Loading2 />
      </Container>
    )
  }

  if (!userPermission) {
    return (
      <Container display="flex" alignItems="center" justifyContent="center" height="100vh">
        <h1 size={30} weight="bold">
          No tienes permisos para ver esta página
        </h1>
      </Container>
    )
  }

  return (
    <Box>
      <TreeMap type="mSand" />
    </Box>
  )
}

export default withAuthenticationRequired(Devices)
