import React from 'react'
import { Button } from '@chakra-ui/react'
import { LogoutIcon } from '../Iconsjsx/logoutIcon'
import { Box } from '@chakra-ui/react'
import { useAuth0 } from '@auth0/auth0-react'

const MsuiteButton = ({label}) => {
  const { logout } = useAuth0()
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      marginTop="2"
    >
      <Button
        className="bg-white border-1-solid border-gray-700 text-gray-700"
        onClick={() => {
          window.location.href = 'https://www.google.com';
          // Si prefieres usar la función de logout con returnTo, puedes hacerlo así:
          logout({
            logoutParams: {
              returnTo: 'https://msuitecloud.com/'
            }
          });
        }}
        display="flex"
        marginBottom={4}
        alignItems="center"
        border={'rounded-md'}
      >
        <LogoutIcon marginRight="4" className={'icon-360'}/>
        {label &&
          <Box as='span' fontSize={14} marginLeft={4}>
            {label}
          </Box>}
      </Button>
    </Box>
  )
}

export default MsuiteButton
