const ScreenIcon = ({className}) => {
  return (
<svg width="18" height="18" viewBox="0 0 24 24" className={className} xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.3808 17.907C9.42771 17.5317 9.74676 17.25 10.125 17.25H13.875C14.2532 17.25 14.5723 17.5317 14.6192 17.907L14.9942 20.907C15.0209 21.1204 14.9546 21.3351 14.8123 21.4964C14.6699 21.6576 14.4651 21.75 14.25 21.75H9.75C9.53488 21.75 9.33011 21.6576 9.18775 21.4964C9.04538 21.3351 8.97911 21.1204 9.0058 20.907L9.3808 17.907ZM10.7871 18.75L10.5996 20.25H13.4004L13.2129 18.75H10.7871Z"  fillOpacity="0.92"/>
<path fillRule="evenodd" clipRule="evenodd" d="M6 21C6 20.5858 6.33579 20.25 6.75 20.25H17.25C17.6642 20.25 18 20.5858 18 21C18 21.4142 17.6642 21.75 17.25 21.75H6.75C6.33579 21.75 6 21.4142 6 21Z"  fillOpacity="0.92"/>
<path fillRule="evenodd" clipRule="evenodd" d="M3 3.75C2.58579 3.75 2.25 4.08579 2.25 4.5V16.5C2.25 16.9142 2.58579 17.25 3 17.25H21C21.4142 17.25 21.75 16.9142 21.75 16.5V4.5C21.75 4.08579 21.4142 3.75 21 3.75H3ZM0.75 4.5C0.75 3.25736 1.75736 2.25 3 2.25H21C22.2426 2.25 23.25 3.25736 23.25 4.5V16.5C23.25 17.7426 22.2426 18.75 21 18.75H3C1.75736 18.75 0.75 17.7426 0.75 16.5V4.5Z"  fillOpacity="0.92"/>
<path d="M1.5 14.25V16.5C1.50111 16.8975 1.65951 17.2784 1.94057 17.5594C2.22163 17.8405 2.60252 17.9989 3 18H21C21.3975 17.9989 21.7784 17.8405 22.0594 17.5594C22.3405 17.2784 22.4989 16.8975 22.5 16.5V14.25H1.5ZM12 17.25C11.8517 17.25 11.7067 17.206 11.5833 17.1236C11.46 17.0412 11.3639 16.9241 11.3071 16.787C11.2503 16.65 11.2355 16.4992 11.2644 16.3537C11.2934 16.2082 11.3648 16.0746 11.4697 15.9697C11.5746 15.8648 11.7082 15.7933 11.8537 15.7644C11.9992 15.7355 12.15 15.7503 12.287 15.8071C12.4241 15.8639 12.5412 15.96 12.6236 16.0833C12.706 16.2067 12.75 16.3517 12.75 16.5C12.75 16.6989 12.671 16.8897 12.5303 17.0303C12.3897 17.171 12.1989 17.25 12 17.25Z"  fillOpacity="0.92"/>
</svg>

  )
}

export default ScreenIcon