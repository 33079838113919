import { createContext, useContext, useState } from 'react';

const DeskNavContext = createContext();

export const useDeskNavState = () => {
    const context = useContext(DeskNavContext);
    if (!context) {
        throw new Error('useDeskNavState must be used within a DeskNavProvider');
    }
    return context;
}

export const DeskNavProvider = ({ children }) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const [currentPage, setCurrentPage] = useState(null)

    const toggleIsExpanded = () => {
        setIsExpanded((prev) => !prev)
    };

    const changePage = (pageName) => {
        setCurrentPage(pageName)
    };

    return (
        <DeskNavContext.Provider value={{ isExpanded, toggleIsExpanded, changePage, currentPage }}>
            {children}
        </DeskNavContext.Provider>
    );
};