import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './app'
import { BrowserRouter as Router } from 'react-router-dom'
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history'
import { ChakraProvider } from '@chakra-ui/react'
import theme from '../src/themes/customTheme' // Importa el tema personalizado
import { NextUIProvider } from '@nextui-org/system'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { DeskNavProvider } from './context/DeskNavContext'
import { PermissionProvider } from './context/PermissionContext'

import './index.css'

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Router>
    <QueryClientProvider client={queryClient}>
      <NextUIProvider>
        <ChakraProvider theme={theme}>
          <DeskNavProvider>
            <Auth0ProviderWithHistory>
                <PermissionProvider>
                  <App />
                </PermissionProvider>
            </Auth0ProviderWithHistory>
          </DeskNavProvider>
        </ChakraProvider>
      </NextUIProvider>
    </QueryClientProvider>
  </Router>
)
