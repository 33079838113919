import { Box, Button, HStack, Icon } from '@chakra-ui/react'
import { Circle } from '../Iconsjsx/Circle'
import { RED, YELLOW, GREEN, GREY } from '../../utils/heatmap'
import { GoAlert } from "react-icons/go";

const ColorButtons = ({colorValues, filters, setFilters}) => {
  
  const handleStatusSelect = (status) => {
    setFilters((prevFilters) => {
      const newStatusFilters = [...prevFilters.statuses]

      if (newStatusFilters?.includes(status)) {
        const statusIndex = newStatusFilters.indexOf(status)
        newStatusFilters.splice(statusIndex, 1)
      } else {
        newStatusFilters.push(status)
      }

      return { ...prevFilters, statuses: newStatusFilters }
    })
  }

  return (
    <Box
      className="color-buttons"   
      right="5px"
      height="24px"
      _hover="none"
      rounded="base"
      flexDirection="row"
      zIndex={999}
      gap="5px"
      spac
    >
      <HStack spacing={2}>
        <Button
          height={"24px"}
          paddingX={"8px"}
          border={"1px solid #E2E8F0"}
          backgroundColor={filters.statuses.includes(RED) ? '#E2E8F0' : 'white'}
          onClick={() => handleStatusSelect(RED)}
        >
          <Circle color={RED}></Circle>
          <Box as="p" fontWeight={500} color={"#535760"} marginBottom={0} marginLeft={4} fontSize={12} className='color-button-caption'>
            Error: {colorValues?.error}
          </Box>
        </Button>
        <Button
          height={"24px"}
          paddingX={"8px"}
          border={"1px solid #E2E8F0"}
          backgroundColor={filters.statuses.includes(YELLOW) ? '#E2E8F0' : 'white'}
          onClick={() => handleStatusSelect(YELLOW)}
        >
          <Circle color={YELLOW}></Circle>
          <Box as="p" fontWeight={500} color={"#535760"} marginBottom={0} marginLeft={4} fontSize={12} className='color-button-caption'>
            Warning: {colorValues?.warning}
          </Box>
        </Button>
        <Button
          height={"24px"}
          paddingX={"8px"}
          border={"1px solid #E2E8F0"}
          backgroundColor={filters.statuses.includes('event') ? '#E2E8F0' : 'white'}
          onClick={() => handleStatusSelect('event')}
        >
          <Icon as={GoAlert} boxSize={4} />
          <Box as="p" fontWeight={500} color={"#535760"} marginBottom={0} marginLeft={2} fontSize={12} className='color-button-caption'>
            Event: {colorValues?.event}
          </Box>
        </Button>
        <Button
          height={"24px"}
          paddingX={"8px"}
          border={"1px solid #E2E8F0"}
          backgroundColor={filters.statuses.includes(GREEN) ? '#E2E8F0' : 'white'}
          onClick={() => handleStatusSelect(GREEN)}
        >
          <Circle color={GREEN}></Circle>
          <Box as="p" fontWeight={500} color={"#535760"} marginBottom={0} marginLeft={4} fontSize={12} className='color-button-caption'>
            Operative: {colorValues?.operative}
          </Box>
        </Button>
        <Button
          height={"24px"}
          paddingX={"6px"}
          border={"1px solid #E2E8F0"}
          backgroundColor={filters.statuses.includes(GREY) ? '#E2E8F0' : 'white'}
          onClick={() => handleStatusSelect(GREY)}
        >
          <Circle color={GREY}></Circle>
          <Box as="p" fontWeight={500} color={"#535760"} marginBottom={0} marginLeft={4} fontSize={12} className='color-button-caption'>
            Offline: {colorValues?.offline}
          </Box>
        </Button>
        </HStack>
    </Box>
  )
}

export default ColorButtons;