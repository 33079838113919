import { extendTheme } from "@chakra-ui/react";

const theme = {
  ...extendTheme({
      colors: {
        table: {
          100:  "#F6FAFC",
        },
        primary: {
          100: "#1E87FA",
          200: "#1A6FCC",
          500: "#1E87FA",
          600: "#1E87FA",
          700: "#1A6FCC"
        },
      },
    }),
};

export default theme;
