import MediaQuery from 'react-responsive'
import { ExpandedMenu } from './ExpandedMenu'
import { usePermissionState } from '../../context/PermissionContext'

export const MobileNav = () => {
  const { userPermission, userData } = usePermissionState()

  return (
    <>
      <MediaQuery minWidth={768}>
        <div className="navbar-nav" id="hamburger">
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={768}>
        <ExpandedMenu userPermission={userPermission} userData={userData} />
      </MediaQuery>
    </>
  )
}