import 'bootstrap/dist/css/bootstrap.min.css'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Box, Container } from '@chakra-ui/react'
import Loading2 from '../components/misc/loading-2'
import { usePermissionState } from '../context/PermissionContext'
import HeatmapView from '../components/heatmap/index'
import { HeatmapProvider } from '../context/HeatmapContext'
import NewAlarm from '../components/Alarms/NewAlarm.modal'

const Devices = () => {
  const { userPermission, isLoading } = usePermissionState()
 
  if (isLoading || !userPermission) {
    return (
      <Container display="flex" alignItems="center" justifyContent="center" height="100vh">
        <Loading2 />
      </Container>
    )
  }

  if (!userPermission || userPermission.device !== true) {
    return (
      <Container display="flex" alignItems="center" justifyContent="center" height="100vh">
        <h1 size={30} weight="bold">
          No tienes permisos para ver esta página
        </h1>
      </Container>
    )
  } 

  return (
    <HeatmapProvider>
      <Box>
        <HeatmapView />
        <NewAlarm/>
      </Box>
    </HeatmapProvider>
  )
}

export default withAuthenticationRequired(Devices)
