const ArrowOpen = ({className}) => {
  return (

    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={29}
    fill="none"
    className={className}
  >
    <circle cx={14} cy={14.047} r={14} fill="#fff" />
    <path
      fill="#1E87FA"
      d="M23.75 14.047c0-5.384-4.366-9.75-9.75-9.75-5.385 0-9.75 4.366-9.75 9.75 0 5.385 4.365 9.75 9.75 9.75 5.384 0 9.75-4.365 9.75-9.75Zm-9.968 4.283a.752.752 0 0 1-.004-1.06l2.452-2.473H9.97a.75.75 0 1 1 0-1.5h6.261l-2.452-2.471a.75.75 0 1 1 1.066-1.057l3.721 3.75a.75.75 0 0 1 0 1.057l-3.721 3.75a.751.751 0 0 1-1.062.004Z"
    />
  </svg>

  )
}

export default ArrowOpen

