export const mapDeviceDataToTableRows = (deviceData) => {
  if (!Array.isArray(deviceData)) return [];

  return deviceData.flatMap((device) => {
    const { serial, active_service, unseen_alarms } = device;

    const location =
      active_service?.type === "service" ? active_service?.name || "-" : "-";

    if (!Array.isArray(unseen_alarms) || unseen_alarms.length === 0) {
      return [];
    }
console.log(unseen_alarms.map(a=>a.alarm_rule?.severity))
    return unseen_alarms.map((alarm) => ({
      id:alarm.id,
      created_at: alarm.createdAt, // DATE
      serial, // DEVICE
      location, // LOCATION
      alarm_name: alarm.alarm_rule?.name || "-",
      severity: alarm.alarm_rule?.severity || 0,
      status: !!alarm?.closedAt,
      viewer: () =>
        console.log("Row Data:", {
          created_at: alarm.createdAt,
          serial,
          location,
          alarm_name: alarm.alarm_rule?.name,
          severity: alarm.alarm_rule?.severity,
        }), 
    }));
  });
};