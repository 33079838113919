const ArrowExit = ({className}) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={29}
    fill="none"
    className={className}
  >
    <circle cx={14} cy={14.048} r={14} fill="#fff" />
    <path
      fill="#1E87FA"
      d="M4.25 14.048c0 5.384 4.365 9.75 9.75 9.75 5.384 0 9.75-4.366 9.75-9.75 0-5.385-4.366-9.75-9.75-9.75-5.385 0-9.75 4.365-9.75 9.75Zm9.968-4.283a.752.752 0 0 1 .004 1.061l-2.452 2.472h6.261a.75.75 0 1 1 0 1.5H11.77l2.452 2.472a.75.75 0 1 1-1.066 1.056l-3.721-3.75a.75.75 0 0 1 0-1.056l3.721-3.75a.75.75 0 0 1 1.062-.005Z"
    />
  </svg>
  )
}

export default ArrowExit;

