import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Link,
  Box,
} from '@chakra-ui/react'
import Burger from '../Iconsjsx/burger'
import LogoutButton from './logout-button'
import { Button, useDisclosure } from '@chakra-ui/react'
import MediaQuery from 'react-responsive'
import { useRef } from 'react'
import ScreenIcon from '../Iconsjsx/ScreenIcon'
import MSuiteMini from '../Iconsjsx/msuitemini'
import ArrowExit from '../Iconsjsx/arrow-exit'
import ArrowOpen from '../Iconsjsx/arrow-open'

export const ExpandedMenu = ({ userPermission }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()
  const logo = 'https://iot.msuitecloud.com/Shared/img/logo_msuite_horizontal.svg'

  return (
    <>
      <Button ref={btnRef} colorScheme="white" onClick={onOpen}>
        <MediaQuery maxWidth={768}>
        <Burger />
        </MediaQuery>
        <MediaQuery minWidth={768}>
        <ArrowOpen />
        </MediaQuery>
      </Button>
      <Drawer
        style={{ backgroundColor: '#F02' }}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="xs"
      >
        <DrawerOverlay />
        <DrawerContent className="drawer">
          <DrawerCloseButton top={35} left={298}>
            <ArrowExit style={{ marginRight: 4 }} />
          </DrawerCloseButton>
          <DrawerHeader marginLeft={6}>
            <img
              className="userImg"
              src={logo}
              alt="React logo"
              width="140"
              height="90"
              id="mSuiteLogo"
            />
          </DrawerHeader>
          <DrawerBody style={{ width: '100%' }}>
            <Box display="flex" alignItems="center">
              <MSuiteMini />
              <Box marginLeft="3" textColor={'blue.500'} fontWeight="bold">
                Virtual Assistant
              </Box>
            </Box>
            {userPermission && userPermission.camera !== false && (
              <Box paddingTop="10">
                <Box as="div" paddingTop={5} textColor={'gray.500'} fontSize={15}>
                  <Link
                    px="12"
                    py={3}
                    href="/device"
                    fontSize={'1rem'}
                  >
                  Device 
                  </Link>
                </Box>
              </Box>
            )}
            {userPermission && userPermission.device !== false && (
              <Box paddingTop="10">
                <Box display="flex" alignItems="center">
                  <ScreenIcon />
                  <Box marginLeft="3" textColor={'blue.500'} fontWeight="bold">
                    Video Analytics
                  </Box>
                </Box>
                <Box paddingTop={5} paddingBottom={3} textColor={'gray.500'} fontSize={15}>
                  <Link
                    px="12"
                    href="/camera"
                    fontSize={'1rem'}
                  >
                    Cameras
                  </Link>
                </Box>
                <Box py={3} textColor={'gray.500'} fontSize={15}>
                  <Link
                    px="12"
                    href="/events"
                    fontSize={'1rem'}
                  >
                    Events
                  </Link>
                </Box>
                                <Box py={3} textColor={'gray.500'} fontSize={15}>
                  <Link
                    px="12"
                    py={3}
                    href="/diagnostic"
                    fontSize={'1rem'}
                  >
                    Diagnostic
                  </Link>
                </Box>
              </Box>
            )}
          </DrawerBody>
          <LogoutButton label={"Sign Out"} />
        </DrawerContent>
      </Drawer>
    </>
  )
}
