import { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import NavBar from './components/nav/NavBar'
import {
  ExternalApi,
  NotFound,
  Camera,
  Events,
  Diagnostic,
  ExternalApiData,
} from './views'
import './app.css'
import { Box } from '@chakra-ui/react'
import { useDeskNavState } from './context/DeskNavContext'
import { usePermissionState } from './context/PermissionContext'



const App = () => {
  const { user, isLoading, loginWithRedirect } = useAuth0()
  const { userPermission } = usePermissionState()
  const { isExpanded } = useDeskNavState()
  
  useEffect(() => {
    if (!user && !isLoading) {
      loginWithRedirect()
    }
  }, [user, isLoading, loginWithRedirect])
  return (
    <div id="app" display="flex">
      <NavBar />
        <Box
          as="main"
          justifyContent="center"
          className={!isExpanded ? 'app-main' : 'app-main-expanded'}
        >
          <Switch className="Navbar1">
            <Route path="/" exact 
            component={
              userPermission?.device === true ? ExternalApi 
              : userPermission?.camera === true ? Camera
              : userPermission?.all === true ? ExternalApi
              : Camera
            } />
            <Route path="/device" component={ExternalApi} />
            {/* <Route path="/device-msand" component={DeviceMsand} />
            <Route path="/device-ehc" component={DeviceEhc} />
            <Route path="/diagnostic" component={Diagnostic} />
            {/* <Route path="/production" component={Production} /> */}
            <Route path="/data" component={ExternalApiData} />
            <Route path="/camera" component={Camera} />
            <Route path="/events" component={Events} />
            <Route path="*" component={NotFound} />
          </Switch>
        </Box>
    </div>
  )
}

export default App
