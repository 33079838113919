export const MARGIN = { top: 10, right: 10, bottom: 50, left: 50 };

export const COLOR_LEGEND_HEIGHT = 60;

export const COLORS = [
  "#D3D3D3",
  "#38A169",
  "#F9D543",
  "#E53E3E",
  "#E74EE0",
];

export const THRESHOLDS = [
  0, 0.01, 0.02, 0.03, 0.09, 0.1, 0.15, 0.25, 0.4, 0.5, 1,
];

export const MAPPED_STATUS_AND_COLORS = {
  '#D3D3D3': 0, // offline
  '#38A169': 1, // operative
  '#F9D543': 2, // warning
  '#E53E3E': 3, // error
}

export const STATUS_NAMES = [
  "offline",
  "operative",
  "warning",
  "error",
  "mgmt"
];

export const COLOR_PRIORITY = [
  "#E53E3E", // error
  "#F9D543", // warning
  "#38A169", // operative
  "#D3D3D3", // offline
  "#E74EE0", //mgmt
]

export const RED = "#E53E3E"
export const YELLOW = '#F9D543'
export const GREEN = "#38A169"
export const GREY = '#D3D3D3'
export const VIOLET = '#FF95DF'
export const BLUE = '#1E87FA'

export const VALUE_PER_COLOR = {
  red: 'error',
  yellow: 'warning',
  green: 'operative',
  grey: 'offline',
  violet: 'management',
}

export const SORTING_CRITERIA = [
  "Status",
  "Model",
  "Asset Owner",
  "Location"
];

export const STATUS_PRIORITY = [
  'error',
  'warning',
  'operative',
  'offline',
  'management'
];

export const PERMISSION_SEEN = 'VIRTUAL_ASSISTANT_DEVICE_SEEN'

export const ADDITIONAL_STATUS_DESCRIPTION = {
  offline_with_service: 'Device is offline but with service',
  online_without_service: 'Device is online but without service',
  firmware_outdated: 'Firmware update is required',
  poor_signal: 'Device with Poor Signal',
  fair_signal:'Device with Fair Signal',
  no_signal: 'Device with no Signal',
  //... add more description
};
