import { Box, Card, CardBody, CardHeader, Flex, Heading, Stack, Text } from "@chakra-ui/react";

const VisualizationCard = ({ title, icon, bgIcon, info, color }) => {
    return (
        <Box bgGradient="linear-gradient(270deg, rgb(0, 178, 213) 0%, rgb(30, 135, 250) 100%)" p="1px" borderRadius="md" marginY="10px" minW="150px">
            <Stack spacing='4' >
                <Card variant='elevated' size="xs" borderRadius="md" p="8px" bg="rgb(247, 250, 252)">
                    <CardHeader>
                        <Flex justifyContent="space-between" alignItems="center">
                            <Heading size='xs' width="90%"> {title} </Heading>
                            {icon &&
                                <Box
                                    w="25px"
                                    h="20px"
                                    borderRadius="5px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    bgGradient={bgIcon}
                                    ml="4px"
                                >
                                    {icon}
                                </Box>
                            }
                        </Flex>
                    </CardHeader>
                    <CardBody>
                        <Text margin={0} fontSize="14px" fontWeight="bold" color={color ? color : "#2D3748"}>{info}</Text>
                    </CardBody>
                </Card>
            </Stack>
        </Box>
    );
}

export default VisualizationCard;