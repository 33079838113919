import { useEvents } from '../../hooks/useEvents'
import { Circle } from '../Iconsjsx/Circle'
import { Table, Thead, Tbody, Tr, Th, Td, Box, Text, Flex, Container } from '@chakra-ui/react'
import ImageModal from './ImageModal'
import { getColor } from '../../utils/color'
import { TableAccordion } from './TableAccordion'
import { useState, useEffect } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import TableEvents from '../Skeletons/events/tableEvents'
import { CucuBurger } from '../Iconsjsx/cucurucho'

const EventTable = () => {
  const { fetchEvents, isLastPage } = useEvents()
  const [eventFilter, setEventFilter] = useState({
    camFilter: [],
    dateFilter: { startDate: null, endDate: null },
    plateFilter: null,
    tagFilter: []
  })

  const { data, fetchNextPage, hasNextPage, isLoading, error } = useInfiniteQuery({
    queryKey: [eventFilter],
    queryFn: fetchEvents,
    getNextPageParam: (page) =>
      page?.currentPage === page?.lastPage ? undefined : page.currentPage + 1,
    initialPageParam: 1,
  })

  useEffect(() => {
    const handleScroll = async (e) => {
      const { scrollHeight, scrollTop, clientHeight } = e.target.scrollingElement
      if (!isLastPage && scrollHeight - scrollTop <= clientHeight * 1.2) {
        await fetchNextPage()
      }
    }
    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [fetchNextPage, isLastPage])

  if (error) {
    return (
      <Container display="flex" alignItems="center" justifyContent="center" height="100vh">
        <h1 size={30} weight="bold">
          {error}
        </h1>
      </Container>
    )
  }


  const nombresCameras = {
    'u262894679.01': 'Cam01 Avalos',
    'u262894679.02': 'Cam01 Seminole',
    'u262894679.03': 'Cam02 Seminole',
  };

  const events = !error ? data?.pages?.flatMap(page => {
    return page.events.map(evento => {
      if (nombresCameras[evento.camera]) {
        evento.camera = nombresCameras[evento.camera];
      }
      return evento;
    });
  }) ?? [] : [];

  return (
    <>
      <Flex flexDirection="row" width="100%" alignItems="center" justifyContent="start" maxWidth="90%">
        <h1>Events</h1>
      </Flex>
      <Box width="100%" maxWidth={{ base: '100%', md: '90%' }}>
        <TableAccordion
          isLoading={isLoading}
          eventFilter={eventFilter}
          setEventFilter={setEventFilter}
        />
        <Table
          overflow="scroll"
          variant="unstyled"
          bg="white"
          colorScheme="table"
          color="#4A5568"
          fontWeight="600"
        >
          <Thead borderBottom="2px" borderColor="gray.100" fontWeight="800">
            <Tr>
              <Th
                fontSize="md"
                padding="0 40 40 0"
                borderRight={'1px solid #E2E8F0'}
              >
                Event 
              </Th>
              <Th
                fontSize="md"
                padding="0 40 40 0"
                borderRight={'1px solid #E2E8F0'}
              >
                Date
              </Th>
              <Th
                fontSize="md"
                padding="0 40 40 0"
                borderRight={'1px solid #E2E8F0'}
              >
                Camera
              </Th>
              <Th
                fontSize="md"
                padding="0 40 40 0"
                borderLeft={'1px solid #E2E8F0'}

              >
                Image
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              events?.map((e, index) => {
                const partes = e?.date?.split(' ');

                const fecha = partes[0];
                const hora = partes[1];
                return (
                  <Tr borderTop='1px solid #E2E8F0' className="event-table" padding="0" key={index}>
                    <Td
                      py="0"
                      px="5px"
                      borderRight='1px solid #E2E8F0'
                    >
                      <div className="circle-container">
                        {e.event.map((value, idx) => (
                          <Circle key={`circle-${idx}`} color={getColor(value)} />
                        ))}
                      </div>
                    </Td>
                    <Td textAlign="center" py="0" px="5px" borderRight='1px solid #E2E8F0' >
                      {fecha} <br />{' '}
                      <Text color="#87A5C7">{hora} </Text>
                    </Td>
                    <Td textAlign="center" py="0" px="5px" borderRight='1px solid #E2E8F0'>
                      {e.camera}
                    </Td>
                    <Td
                      textAlign="center"
                      py="0"
                      px="5px"
                      margin="auto 0"
                    >
                      <ImageModal event={e} />
                    </Td>
                  </Tr>
                )
              })}
          </Tbody>
        </Table>
        <Table>
          <Tbody padding="0" margin="0">
            {((!isLoading && hasNextPage && events.length > 0) || isLoading) && (
              <>
                {Array.from({ length: !isLoading ? 1 : 6 }).map((_, index) => (
                  <Tr key={index} width="100%" padding="0" margin="0">
                    <Td width="100%" padding="0" margin="0">
                      <TableEvents />
                    </Td>
                  </Tr>
                ))}
              </>
            )}
            {(!isLoading && events.length <= 0) && (
              <>
                <Tr width="100%" padding="0" margin="0">
                  <Td width="100%" padding="0" margin="0">
                    <Box padding='5' width="100%" display="flex" gap="10px" flex-direction='row' alignItems="center" justifyContent="space-between" boxShadow='lg' bg='white'>
                      No events found!
                    </Box>
                  </Td>
                </Tr>
              </>
            )}
          </Tbody>
        </Table>
      </Box>
    </>
  )
}

export default EventTable
