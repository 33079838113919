import * as React from "react"
const ColumnFilters = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    style={{marginLeft: '5px'}}
    fill="none"
    {...props}
  >
    <path
      fill="#1E87FA"
      d="M.438 3.281h13.124v1.313H.438V3.28Zm2.187 3.063h8.75v1.312h-8.75V6.344ZM5.25 9.406h3.5v1.313h-3.5V9.406Z"
    />
  </svg>
)
export default ColumnFilters