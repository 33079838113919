import { createContext, useContext, useState } from 'react'
import { useEffect } from 'react'
import { getProtectedResource } from '../auth/auth-fetch'
import { useAuth0 } from '@auth0/auth0-react'
import { GREEN, RED, YELLOW, GREY, COLOR_PRIORITY } from '../utils/heatmap'
import { getIsMobile } from '../utils/device'

const initialStatusValues = { error: 0, operative: 0, warning: 0, offline: 0, event: 0 }
const HeatmapContext = createContext()

export const useHeatmapContext = () => {
    const context = useContext(HeatmapContext)
    if (!context) {
        throw new Error('useHeatmapContext must be used within a HeatmapProvider')
    }
    return context
}

export const HeatmapProvider = ({ children }) => {
    const URL_BACK = process.env.REACT_APP_URL_BACK
    const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE
    const { user, getAccessTokenSilently } = useAuth0()
    const [isLoading,  setIsLoading] = useState(false);
    // device data
    const [deviceData, setDeviceData] = useState([]);
    const [options, setOptions] = useState({
        models: [],
        assetsOwners: [],
        operatorCompanies: [],
        serviceCompanies: []
    });
    const [filteredDeviceData, setFilteredDeviceData] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const isMobile = getIsMobile(navigator, window);
    const [filters, setFilters] = useState({
      statuses: isMobile ? [RED] : [RED, YELLOW, GREEN, 'event'],
      devices: [],
      models: [],
      owners: [],
      operators: [],
      services: []
    })

    const [sortBy, setSortBy] = useState('status');
    const [sortVertically, setSortVertically] = useState(!isMobile);

    const [statusValues, setStatusValues] = useState(initialStatusValues);

    const validateOperatorFilter = (device) => {
      const includeNoService = filters.operators.includes("NO SERVICE AVAILABLE");

      return (includeNoService && !device?.active_service?.operatorAccount)
        || filters.operators.includes(
        device?.active_service?.operatorAccount?.name?.toUpperCase()
      );
    };
    const validateServiceFilter = (device) => {
      const includeNoService = filters.services.includes("NO SERVICE AVAILABLE");

      return (includeNoService && !device?.active_service?.serviceAccount)
        || filters.services.includes(device?.active_service?.serviceAccount?.name?.toUpperCase()
      );
    };

    const validateAccCreatorFilter = (device) => {
      const includeNoService = filters.owners.includes("NO SERVICE AVAILABLE");

      return (includeNoService && !device?.active_service?.accountCreator)
        || filters.owners.includes(device?.active_service?.accountCreator?.name?.toUpperCase()
      );
    };

    const validateStatusesFilter = (device) => {
      if (filters.statuses.includes('event'))
        return !!device.additional_statuses.length || filters.statuses.includes(device.device_status_color);
      return filters.statuses.includes(device.device_status_color);
    }

    useEffect(() => {
        // Set filters
        const filteredDevices = deviceData?.filter(
            (device) => 
                (filters.statuses.length === 0 || validateStatusesFilter(device)) &&
                (filters.devices.length === 0 ||
                    filters.devices.includes(device.serial)) &&
                (filters.models.length === 0 ||
                    filters.models.includes(device?.device_model?.name)) &&
                (filters.owners.length === 0 || validateAccCreatorFilter(device)) && 
                (filters.operators.length === 0 || validateOperatorFilter(device)) &&
                (filters.services.length === 0 || validateServiceFilter(device))
        );

        setFilteredDeviceData(filteredDevices?.length ? sortByCriteria(filteredDevices) : []);

        // Set colors
        setStatusValues(initialStatusValues)
        filteredDevices.forEach(device => {
          // events
          if (device.additional_statuses.length)
            setStatusValues(prevCounter => ({...prevCounter, event: prevCounter.event + 1}));

          switch (device.device_status_color) {
            case RED: // error
              setStatusValues(prevCounter => ({...prevCounter, error: prevCounter.error + 1}));
              break;
            case YELLOW: // warning
              setStatusValues(prevCounter => ({...prevCounter, warning: prevCounter.warning + 1 }));
              break;
            case GREEN: // operative
              setStatusValues(prevCounter => ({...prevCounter, operative: prevCounter.operative + 1 }));
              break;
            case GREY: // offline
              setStatusValues(prevCounter => ({...prevCounter, offline: prevCounter.offline + 1 }));
              break;
            default:
              break;
          }
        });

    }, [filters, sortBy, deviceData])

    const fetchDeviceData = async (type) => {
        try {
          setIsLoading(true);
          const token = await getAccessTokenSilently({
            authorizationParams: {
              audience: AUDIENCE,
            },
          })
          const urlFetch = `${URL_BACK}/api/devices-assistance/${user?.sub}${ type ? `?clientFilter=${type}`: ''}`
    
          // models, locations, assetsOwners
          const { data } = await getProtectedResource(urlFetch, token);
          if (!data) return;
    
          const devices = Object.values(data.devices).flat();
          setDeviceData(devices);
          setOptions({
            models: data.models,
            locations: data.locations,
            assetsOwners: data.accounts_creators,
            operatorCompanies: data.operator_companies,
            serviceCompanies: data.service_companies
          })
          //data filtrada de todos los dispositivos
          setFilteredDeviceData(sortByCriteria(devices));
        } catch (error) {
          console.error(error)
        } finally {
          setIsLoading(false);
        }
    }

    function getFilteredItems(optionArray, itemArray) {
      return optionArray.length !== itemArray.length ? itemArray : [];
    }

    const filterCounter = () => {
        const { owners, devices, models, operators, services } = filters;

        const allFilters = [
          ...devices,
          ...getFilteredItems(options.models, models),
          ...getFilteredItems(options.assetsOwners, owners),
          ...getFilteredItems(options.operatorCompanies, operators),
          ...getFilteredItems(options.serviceCompanies, services),
        ];

        return allFilters.length;
      }

    const sortByCriteria = (device = []) => {
        switch (sortBy) {
          case 'Location':
            return [...device].sort((a, b) => {
              if (a.location < b.location) return -1;
              if (a.location > b.location) return 1;
              return 0;
            });
          case 'Asset Owner':
            return [...device].sort((a, b) => {
              if (a.devicetag2 < b.devicetag2) return -1;
              if (a.devicetag2 > b.devicetag2) return 1;
              return 0;
            });
          case 'Model':
            return [...device].sort((a, b) => {
              if (a.device_type < b.device_type) return -1;
              if (a.device_type > b.device_type) return 1;
              return 0;
            });
          // by status
          default:
            return [...device].sort((a, b) => {
              const indexA = COLOR_PRIORITY.indexOf(a.device_status_color);
              const indexB = COLOR_PRIORITY.indexOf(b.device_status_color);
              return indexA - indexB;
            });
        }
    }

    const resetFilters = () => {
      setFilters({
        statuses: [],
        devices: [],
        models: [],
        owners: [],
        operators: [],
        services: []
    });
    }

    return (
        <HeatmapContext.Provider
            value={{
                isLoading,
                deviceData,
                filteredDeviceData,
                selectedDevice,
                filters,
                options,
                statusValues,
                sortBy,
                sortVertically,
                fetchDeviceData,
                setDeviceData,
                setSelectedDevice,
                setFilters,
                setSortBy,
                setSortVertically,
                filterCounter,
                resetFilters
            }}
        >
            {children}
        </HeatmapContext.Provider>
    )
}
