import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container } from 'reactstrap'
import axios from 'axios' // Importa la librería axios
import { Card } from '@nextui-org/react'
import { withAuthenticationRequired } from '@auth0/auth0-react'

const App = () => {
  const [data, setData] = useState([])

  const sortByEstado = (a, b) => {
    if (a.Estado === 'En mapa' && b.Estado !== 'En mapa') {
      return -1
    } else if (a.Estado !== 'En mapa' && b.Estado === 'En mapa') {
      return 1
    } else if (a.Estado === 'En proceso' && b.Estado === 'Terminado') {
      return -1
    } else if (a.Estado === 'Terminado' && b.Estado === 'En proceso') {
      return 1
    }
    return 0
  }

  useEffect(() => {
    const fetchData = () => {
      axios
        .get('https://u2ivr6ukxc.us-east-1.awsapprunner.com/api/query3')
        .then((response) => {
          setData(response.data)
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    }

    fetchData()

    const interval = setInterval(() => {
      fetchData()
    }, 60000)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <Container>
        <br />
        <h1 style={{ textAlign: 'center' }}> </h1>
        {data.sort(sortByEstado).map((dato) => (
          <Card
            key={dato.id}
            css={{
              marginBottom: '1rem',
              backgroundColor:
                dato.Estado === 'En proceso'
                  ? 'var(--nextui-colors-primaryLight)'
                  : dato.Estado === 'En mapa'
                  ? 'white'
                  : 'transparent',
              opacity: dato.Estado === 'En mapa' ? 0.5 : 1,
            }}
          >
            <Card.Body>
              <h4>{dato.Titulo}</h4>
              <h4>{dato.Descripcion}</h4>
              <h4>{dato.Estado}</h4>
            </Card.Body>
          </Card>
        ))}
      </Container>
    </>
  )
}

export default withAuthenticationRequired(App)
