import LogsTable from "./NewLogsTable";
import { ThemeProvider, createTheme } from '@mui/material/styles';

const muiTheme = createTheme();
export const TableSwitcher = () => {
  return (
    <ThemeProvider theme={muiTheme}>
      <LogsTable />
    </ThemeProvider>
  );
};


