import React from "react";
import img1 from '../../images/password-login-svgrepo-com.svg';

const HeroLogin = () => (
  <div className="text-center">
    <div className="text-center hero">
      <img className="userImg" src={img1} width="250" height="250" style={{ margin: "0%", marginTop: "40%"}} alt="" />
      <p className="lead" style={{ marginTop: "8%"}}>
        {/* Sign in to see your devices */}
        Inicia sesión para ver tus dispositivos
      </p>
    </div>
  </div>
);

export default HeroLogin;