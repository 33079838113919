import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Image,
  Button,
  useDisclosure,
  Box,
} from '@chakra-ui/react'
import Download from '../Iconsjsx/DownloadSvg'
import SvgEye from '../Iconsjsx/SvgEye'

const ImageModal = ({ event }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const eventDesc = (eventItems) => {
    let personCount = 0,
      carCount = 0,
      truckCount = 0

    // const eventTypes = {
    //   0: 'Person',
    //   2: 'Cars',
    //   7: 'Trucks',
    // }

    eventItems.forEach((item) => {
      eventItems = item.replace(/'/g, '')
      if (eventItems === 'Person') {
        personCount++
      } else if (eventItems === 'Car') {
        carCount++
      } else if (eventItems === 'Truck') {
        truckCount++
      }
    })

    const peopleStr = personCount > 0 ? `People: ${personCount}. ` : ''
    const carsStr = carCount > 0 ? `Cars: ${carCount}. ` : ''
    const trucksStr = truckCount > 0 ? `Trucks: ${truckCount}.` : ''
    return `${peopleStr} ${carsStr} ${trucksStr}`
  }
  const partes = event?.date?.split(' ');

  const fecha = partes[0]; // Obtiene la parte de la fecha
  const hora = partes[1];

  const downloadImage = () => {
    const imageUrl = event.processedphotourl

    // Crea un enlace temporal
    const link = document.createElement('a')
    link.href = imageUrl
    link.download = 'image.jpg'
    link.target = '_blank'

    // Dispara un clic en el enlace para iniciar la descarga
    link.click()
  }

  return (
    <>
      <Button
        colorScheme="primary"
        fontSize="16px"
        py="2px"
        px="10px"
        onClick={onOpen}
      >
        <span className="me-2">View</span> <SvgEye fill="#ffffff" />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent marginX={{ base: '8px', md: '0' }}>
          <ModalHeader
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            paddingBottom="0px"
            width="100%"
          >
            <Box>
              Image from {event.camera}
              <h4 className="image-date">
                {fecha}{' '}
                <span className="color-celeste">{hora}</span>
              </h4>
              <h5 className="image-date text-black">Event found:</h5>
              <span className="image-date">{eventDesc(event.event)}</span>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="100px"
              justifyContent="flex-end"
              position="absolute"
                top="82px"
                right="2px"
            >
              <Button
                px="5px"
                paddingBottom="5px"
                marginX={{ base: '10px', md: '15px' }}
                colorScheme="primary"
                onClick={downloadImage}
                display="flex"
                justifyContent="center"
                alignItems="center"
                fontSize="25px"
                borderRadius="100%"
              >
                <Download />
              </Button>
            </Box>
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody px="5px" paddingBottom="5px" border="0">
            <Image src={event.processedphotourl} width="100%" rounded="base" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ImageModal
