import React from 'react';

const BatteryStatusComponent = (props) => {
  const { batterydata } = props;

  const determineBatteryLevel = (inputVoltage) => {
    if (inputVoltage >= 800 && inputVoltage <= 1100) {
      return 25;
    } else if (inputVoltage > 1100 && inputVoltage <= 1200) {
      return 50;
    } else if (inputVoltage > 1200 && inputVoltage <= 1300) {
      return 75;
    } else if (inputVoltage > 1300) {
      return 100;
    } else {
      return 0;
    }
  };

  const batteryLevel = determineBatteryLevel(batterydata.PV_ARRAY_INPUT_V);

  const renderBatteryBars = () => {
    const bars = Array.from({ length: 4 }, (_, index) => {
      let trueIndex = (index + 1) * 25;
      let colorClass;
      
      if (batteryLevel <= 25 && index === 0) {
        colorClass = 'red';
      } else if (batteryLevel >= trueIndex && batteryLevel < trueIndex + 25 && batteryLevel !== 100) {
        colorClass = 'green';
      } else if (batteryLevel >= trueIndex + 25 && batteryLevel < 100) {
        colorClass = 'green';
      } else if (batteryLevel === 100) {
        colorClass = 'blue';
      } else {
        colorClass = 'empty';
      }
   
      return (
        <div
          key={index}
          className={`battery-bar ${colorClass}`}
          style={{ 
            left: index === 0 ? '5px' : index === 1 ? '20px' : index === 2 ? '35px' : '50px',
          }}
        ></div>
      );
    });
  
    return bars;
  };

  return (
    <div className="container-status">
      <div id="battery" className="battery-container">
        {renderBatteryBars()}
      </div>
    </div>
  );
};

export default BatteryStatusComponent;
