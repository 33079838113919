import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading2 from '../misc/loading-2';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useCamBattery } from "../../hooks/useCamBattery"
import { Container, Flex} from "@chakra-ui/react";


const Battery = () => {

    const { batterydata, isLoading } = useCamBattery();

  useEffect(() => {
    if (!batterydata || batterydata.length === 0 ) return
    let root = am5.Root.new("chartdiv");

    const myTheme = am5.Theme.new(root);

    // Move minor label a bit down
    myTheme.rule("AxisLabel", ["minor"]).setAll({
      dy: 1
    });

    // Tweak minor grid opacity
    myTheme.rule("Grid", ["minor"]).setAll({
      strokeOpacity: 0.08
    });

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root),
      myTheme
    ]);


    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      paddingLeft: 0
    }));


    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomX"
    }));
    cursor.lineY.set("visible", false);


    function generateData(i) {
      const dividedValue = batterydata[i].PV_ARRAY_INPUT_V;
      return {
        date: parseFloat(batterydata[i].ts),
        value: dividedValue
      };
    }

    function generateDatas(count) {
      let data = [];
      for (var i = count - 1; i >= 0; --i) {
        data.push(generateData(i));
      }
      return data;
    }


    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      maxDeviation: 0,
      baseInterval: {
        timeUnit: "minute",
        count: 20
      },
      renderer: am5xy.AxisRendererX.new(root, {
        minorGridEnabled: false,
        minGridDistance: undefined,    
        minorLabelsEnabled: false
      }),
      tooltip: am5.Tooltip.new(root, {})
    }));

    xAxis.set("minorDateFormats", {
      day: "dd",
      month: "MM"
    });

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {})
    }));


    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(am5xy.LineSeries.new(root, {
      name: "Series",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      valueXField: "date",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}"
      })
    }));

    // Actual bullet
    series.bullets.push(function () {
      let bulletCircle = am5.Circle.new(root, {
        radius: 5,
        fill: series.get("fill")
      });
      return am5.Bullet.new(root, {
        sprite: bulletCircle
      })
    })

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    chart.set("scrollbarX", am5.Scrollbar.new(root, {
      orientation: "horizontal"
    }));

    let data = generateDatas(batterydata.length);
    series.data.setAll(data);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);
    chart.appear(1000, 100);

    return () => root.dispose()
  
  },[batterydata, isLoading])

  return (
    isLoading ? 
    <Container display="flex" alignItems="center" justifyContent="center" height="100vh">
      <Loading2 />
    </Container>
     : (<>
      <Flex flexDirection="row" width="100%" alignItems="center" justifyContent="start" maxWidth="1500px">
        <h1>Battery Diagnostic</h1>
      </Flex>
      <div id="chartdiv" style={{ width: '100%', height: '500px' }}></div>
     </>
     )
  );
};

export default Battery;