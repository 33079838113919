import React from 'react';
import { Button } from '@nextui-org/react';

const NotFound = () => (
  <div className='centro'>
    <h1>404 - Not Found!</h1>
    <Button size="lg" onClick={() => window.location.replace('/')}>Go Home</Button>
  </div>
);

export default NotFound;