// import { useEffect } from 'react'
// import * as am5 from '@amcharts/amcharts5'
// import * as am5hierarchy from '@amcharts/amcharts5/hierarchy'
// import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
// import CustomModal from './CustomModal'
// import { useDisclosure } from '@chakra-ui/react'
// import { useAlarms } from '../../hooks/useAlarms'
// import { useAuth0 } from '@auth0/auth0-react'
// import Toast from '../Toast'
// import FiltersTreemap from './filtersTreemap'
// import { obtainTreeData } from '../../utils/treemap'
// import { useTreemapContext } from '../../context/TreemapContext'

// function TreeMap() {
//   const { user } = useAuth0()

//   const { onOpen, onClose, isOpen } = useDisclosure()
//   const {
//     treeData,
//     deviceData,
//     filters,
//     filteredDevices,
//     setThisDeviceData,
//     fetchTreeData,
//     locations,
//     RED, YELLOW, GREEN, GREY, VIOLET, BLUE
//   } = useTreemapContext()
//   const { messages } = useAlarms()
//   const idAuth0 = user?.sub


//    useEffect(() => {
//      const fetchData = async () => {
//        await fetchTreeData()
//      }
//      fetchData()
//      // intervalos de 1 minuto
//       const interval = setInterval(fetchData, 60000)
//       return () => clearInterval(interval)
//       // eslint-disable-next-line react-hooks/exhaustive-deps
//    }, [])


//   const handleCardClick = (deviceName) => {
//     const selectedData = deviceData.find(
//       (item) => item.devicetag1 === deviceName
//     )
//     setThisDeviceData(selectedData)
//     onOpen()
//   }

//   useEffect(() => {
//     if (!deviceData) return

//     let root = am5.Root.new('chartdiv')
//     const myTheme = am5.Theme.new(root)
//     myTheme
//       .rule('RoundedRectangle', ['hierarchy', 'node', 'shape', 'depth1'])
//       .setAll({
//         strokeWidth: 10,
//       })

//     myTheme
//       .rule('RoundedRectangle', ['hierarchy', 'node', 'shape', 'depth2'])
//       .setAll({
//         fillOpacity: 0,
//         strokeWidth: 0.5,
//         strokeOpacity: 1,
//       })

//     myTheme
//       .rule('Label', ['hierarchy', 'node', 'shape', 'depth1', 'depth2'])
//       .setAll({
//         fontSize: 45,
//         fontWeight: 'bold',
//       })

//     root.setThemes([am5themes_Animated.new(root), myTheme])

//     // Create wrapper container
//     let container = root.container.children.push(
//       am5.Container.new(root, {
//         width: am5.percent(100),
//         height: am5.percent(100),
//         layout: root.verticalLayout,
//       })
//     )

//     // Create series
//     // https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
//     let location = container.children.push(
//       am5hierarchy.Treemap.new(root, {
//         initialDepth: 2,
//         valueField: 'value',
//         categoryField: 'name',
//         childDataField: 'children',
//         nodePaddingOuter: 0,
//         nodePaddingInner: 0,
//         tooltip: false,
//       })
//     )

//     location.nodes.template.set('tooltipText', '')

//     location.rectangles.template.events.on('click', function (ev) {
//       // buscamos el ultimo childreen el cual siempre tiene value
//       if (ev?.target?.dataItem?.dataContext?.value) {
//         const deviceName = ev.target.dataItem.dataContext.name
//         handleCardClick(deviceName)
//       }
//     })

//     //labels
//     location.labels.template.setAll({
//       fontSize: 35,
//       fontWeight: 'bold',
//       marginLeft: 10,
//       marginRight: 10,
//     })

//     location.get('colors').set('step', 2)

//     // colores rectangulos
//     location.rectangles.template.adapters.add('fill', (fill, target) => {
//       if (target.dataItem) {
//         let deviceName = target.dataItem.dataContext.name;

//         // si el deviceName es un dispositivo en especifico
//         for (let device of deviceData) {
//           if (device.devicetag1 === deviceName) {
//             if (device.seen && device.seen !== null) {
//               if (device?.seen?.user === idAuth0) {
//                 if (device.color === RED && device?.seen?.problem && device?.seen?.deleted_at === null) {
//                   return BLUE
//                 } else {
//                   return device.color
//                 }
//               }
//             }
//             return device.color
//           }
//           // si el deviceName es una location/cliente
//           if (device.location === deviceName) {
//             let colorCount = {
//               red: 0,
//               green: 0,
//               yellow: 0,
//               gray: 0,
//               violet: 0,
//             }
//             let deviceColor = device.color

//             if (deviceColor === RED) {
//               colorCount.red += 1
//             } else if (deviceColor === YELLOW) {
//               colorCount.yellow += 1
//             } else if (deviceColor === GREEN) {
//               colorCount.green += 1
//             } else if (deviceColor === VIOLET) {
//               colorCount.violet += 1
//             } else {
//               colorCount.gray += 1
//             }

//             if (colorCount.red > 0) {
//               return RED
//             } else if (colorCount.red <= 0 && colorCount.yellow > 0) {
//               return YELLOW
//             } else if (colorCount.red <= 0 && colorCount.yellow <= 0 && colorCount.green > 0) {
//               return GREEN
//             }
//           }
//         }
//         return GREY
//       }
//     })


//     // container.children.moveValue(
//     //   am5hierarchy.BreadcrumbBar.new(root, {
//     //     series: location,
//     //   }),
//     //   0
//     // )
//     const noFiltersApplied = Object.values(filters).every(filter => filter.length === 0);

//     if ((filteredDevices.length === 0 || filteredDevices.length === deviceData.length) && noFiltersApplied ) {
//       location.data.setAll(treeData)
//     } else {
//       location.data.setAll(obtainTreeData(filteredDevices, locations, am5))
//     }
//     location.set('selectedDataItem', location.dataItems[0])

//     return () => {
//       root.dispose()
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [treeData])


//   return (
//     <>
//       <FiltersTreemap  />
//       <div id="chartdiv" style={{ height: '92dvh', marginTop: 10 }}>
//         {messages.map((message, index) => (
//           <Toast
//             key={index}
//             status={message.type}
//             message={message.error}
//             title={message.title}
//             showError={message.show}
//             delay={index * 1000}
//           />
//         ))}
//         <CustomModal isOpen={isOpen} onClose={onClose}/>
//       </div>
//     </>
//   )
// }

// export default TreeMap



//     // const fetchDataAndUpdateTreemap = async () => {
//     //   const token = await getAccessTokenSilently({
//     //     authorizationParams: {
//     //       audience: AUDIENCE,
//     //     },
//     //   })
//     //   let urlFetch = `${URL_BACK}/api/devices-assistance/${user?.sub}`

//     //   const response = await fetch(urlFetch, {
//     //     headers: {
//     //       Authorization: `Bearer ${token}`,
//     //     },
//     //   });
//     //   const res = await response.json();
//       // let filteredDevices = res.devices;
//       // verificamos si existe un filter aplicado, si existe lo aplicamos
//       // if (filters.locations.length > 0 || filters.colors.length > 0 || filters.devices.length > 0 || filters.models.length > 0 || filters.devicetag2.length > 0) {
//       //   setFilteredDevices(res.devices?.filter(device =>
//       //     (filters.locations.length === 0 || filters.locations.includes(device.location)) &&
//       //     (filters.colors.length === 0 || filters.colors.includes(device.color)) &&
//       //     (filters.devices.length === 0 || filters.devices.includes(device.device_id)) &&
//       //     (filters.models.length === 0 || filters.models.includes(device.device_type)) &&
//       //     (filters.devicetag2.length === 0 || filters.devicetag2.includes(device.devicetag2))
//       //   ) || [])
//       // }
//       // Actualiza los valores de los nodos hijos
//       // let dataItems = location?.dataItems[0]?.get("children");

//       // if (filters.locations.length > 0 || filters.colors.length > 0 || filters.devices.length > 0 || filters.models.length > 0 || filters.devicetag2.length > 0) {
//       //   for (let i = 0; i < dataItems?.length; i++) {
//       //     let dataItem = dataItems[i];
//       //     let children = dataItem?.get("children");

//       //     for (let c = 0; c < children.length; c++) {
//       //       let child = children[c];
//       //       let childData = child.dataContext;
//       //       let newChildData = newData[0]?.children[i]?.children[c];
//       //       let newValue = newChildData?.value;

//       //       if (JSON.stringify(newChildData) !== JSON.stringify(childData)) {
//               // entonces debemos ver cuales son las diferencias
//               // si el value cambió, entonces actualizamos el valor
//     //           if (newChildData?.value !== childData?.value) {
//     //             newValue = newChildData?.value;
//     //           }
//     //           if (newChildData === null || newChildData === undefined) {
//     //             newValue = 0;
//     //           }
//     //           child.set("value", newValue);
//     //           child.set("valueWorking", newValue);

//     //         } else {
//     //           child.set("value", newValue);
//     //           child.set("valueWorking", newValue);
//     //         }
//     //       }
//     //     }
//     //   } else {
//     //     for (let i = 0; i < dataItems?.length; i++) {
//     //       let dataItem = dataItems[i];
//     //       let children = dataItem?.get("children");
//     //       for (let c = 0; c < children.length; c++) {
//     //         let child = children[c];
//     //         let newChildData = newData[0]?.children[i]?.children[c];
//     //         let newValue = newChildData?.value;
//     //         child.set("value", newValue);
//     //         child.set("valueWorking", newValue);
//     //       }
//     //     }
//     //   }
//     // }
//       // }

//     // location.appear(1000, 300);
//     // const interval = setInterval(fetchDataAndUpdateTreemap, 60000);
//     // return () => clearInterval(interval);
