import { Modal, ModalContent, ModalOverlay, ModalBody, ModalHeader, Divider, Flex, useDisclosure } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { AlarmViewerProvider } from "./AlarmViewer.provider";
import { useHeatmapContext } from "../../context/HeatmapContext";
import { usePermissionState } from "../../context/PermissionContext";
import { PERMISSION_SEEN } from "../../utils/heatmap";

const NewAlarm = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { unseenAlarms } = useHeatmapContext();
  const permissions = usePermissionState();
  const permissionSeen = !!permissions?.userData?.find(
    permission => permission.permission_name === PERMISSION_SEEN
  );

  const playSound = () => {
    let urlAudio = '/Emergencia-SOS.mp3';
    const audio = new Audio(urlAudio);
    audio.addEventListener('error', (error) => {
      console.error('Error al cargar o reproducir el archivo de sonido:', error);
    });
    audio.play().catch((error) => {
      console.error('Error al reproducir el sonido:', error);
    });
  };

  useEffect(() => {
    unseenAlarms?.length > 0 && permissionSeen ? onOpen() : onClose();
  }, [unseenAlarms, onOpen, onClose, permissionSeen]);

  useEffect(() => {
    let intervalId;
    if (isOpen && permissionSeen) {
      playSound();
      intervalId = setInterval(() => {
        playSound();
      }, 8000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isOpen, permissionSeen]);

  return (
    <Modal isOpen={isOpen} isCentered size={'7xl'} ml={2}>
      <ModalOverlay
        backdropFilter='blur(5px)'
      />
      <ModalContent>
        <ModalHeader>
          Alarm Viewer
          <Divider/>
        </ModalHeader>
        <ModalBody>
          <Flex justify={'center'} align={'center'}>
            <AlarmViewerProvider/>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default NewAlarm