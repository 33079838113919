import React, { useEffect, useRef, useState } from 'react';
import {
  List,
  ListItem,
  ListIcon,
  InputGroup,
  InputRightElement,
  Input,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';
import { SmallAddIcon, SmallCloseIcon, SearchIcon } from "@chakra-ui/icons";

const SearchBar = ({ selectedItems, setSelectedItems, data }) => {
  const [filter, setFilter] = useState('');
  const [results, setResults] = useState(data);
  const [inputActive, setInputActive] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (data && data.length > 0) {
      setResults(data.sort());
    }
  }, [data]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setFilter(value);
    const filteredResults = data.filter(item =>
      item.toLowerCase().includes(value.toLowerCase())
    );
    setResults(filteredResults);
  };

  const handleInputFocus = () => {
    setInputActive(true);
  };

  const onMouseLeave = () => {
    setInputActive(false);
    inputRef.current.blur();
  };

  const handleItemClick = (item) => {
    let updatedSelectedItems;
    if (selectedItems.includes(item)) {
      updatedSelectedItems = selectedItems.filter(selectedItem => selectedItem !== item);
    } else {
      updatedSelectedItems = [...selectedItems, item];
    }
    setSelectedItems(updatedSelectedItems);
    // re-sort list
    const newResults = [...results.sort()];
    updatedSelectedItems.forEach(itSelect => {
      const index = newResults.findIndex(i => i === itSelect);
      const [selectedItem] = newResults.splice(index, 1);
      newResults.unshift(selectedItem);
    })
    setResults(newResults);

    setFilter(''); // Clear the input field after selecting an item
    inputRef.current.focus(); // Refocus on the input field for new input
  };

  const handleResetFilters = () => {
    setSelectedItems([]);
    setResults(results.sort());
  }

  return (
    <Box position="relative" width="100%" >
      <Box onMouseLeave={onMouseLeave} >
        <InputGroup height="30px" position="relative" zIndex={1}>
          <InputRightElement height="30px" zIndex={2}>
          {
            selectedItems.length > 0 ? (
              <Flex
                key={"close"}
                alignItems="center"
                justifyContent="center"
                bg="gray.100"
                borderRadius="md"
                padding="2px 4px"
                margin="1px"
                cursor="pointer"
                onClick={() => handleResetFilters()}
                whiteSpace="nowrap"
                zIndex={3}
              >
                <SmallCloseIcon  color='gray.300' />
              </Flex>
            ) : (
              <SearchIcon color={inputActive ? 'gray.500' : 'gray.300'} />
            )
          }
          </InputRightElement>
          <Flex align="center" padding="0 4px" border="1px solid" borderColor="gray.300" borderRadius="md" width="100%" position="relative" overflow="hidden">
            <Flex overflowX="auto" flexWrap="nowrap" align="center" padding="0px" position="relative">
            {selectedItems.length > 3 ? (
              <Text position="absolute" fontSize="sm" fontWeight="500" marginLeft="2px" margin="0" zIndex={99}>Selected: {selectedItems.length}</Text>
            ) : (
              selectedItems.map((selectedItem, index) => (
                <Flex
                  key={selectedItem + index}
                  alignItems="center"
                  justifyContent="center"
                  bg="gray.100"
                  borderRadius="md"
                  padding="2px 4px"
                  margin="1px"
                  cursor="pointer"
                  onClick={() => handleItemClick(selectedItem)}
                  whiteSpace="nowrap"
                  position="absolute"
                  left={`${index * 60}px`}
                >
                  <Text fontSize="sm" fontWeight="500" margin="0 0 0 2px" zIndex={99}>{selectedItem.length > 6 ? `${selectedItem.slice(0,5)}...` : selectedItem}</Text>
                </Flex>
              ))
            )}
              <Input
                ref={inputRef}
                type="text"
                value={filter}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                flex="1"
                border="none"
                _focus={{ 
                outline: "none",
                borderColor: "transparent"
              }}
              />
            </Flex>
          </Flex>
        </InputGroup>
        {inputActive && (
          <Box maxHeight='300px' overflowX="hidden" overflowY="auto" position="fixed" bg="white" zIndex="1" width="260px" borderRadius="0px 0px 8px 8px" boxShadow="0px 10px 27px -11px rgba(0,0,0,0.79)">
            <List paddingX="0.6rem"  display="flex" gap="4px" flexDirection="column">
              {results.length > 0 ? (
                results.map((result, index) => (
                  <ListItem _hover={{ bg: "#E9F3FF" }} key={index} onClick={() => handleItemClick(result)} bg={selectedItems.includes(result) ? "#E9F3FF" : "transparent"} cursor="pointer" color={selectedItems.includes(result) ? "primary.100" : "black"} fontWeight="600" display="flex" justifyContent="flex-start" alignItems="center" padding="5px" borderRadius="4px" fontSize={12}>
                    <ListIcon as={selectedItems.includes(result) ? SmallCloseIcon : SmallAddIcon} color={selectedItems.includes(result) ? 'red.400' : 'gray.500'} />
                    {result}
       
                  </ListItem>
                ))
              ) : (
                <ListItem fontWeight="600" display="flex" justifyContent="flex-start" alignItems="center" padding="5px" borderRadius="4px">Results Not Found</ListItem>
              )}   
            </List>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SearchBar;
