import React from "react";
import HeroHome from "./herohome";
import HeroLogin from "./herologin";
import { useAuth0 } from "@auth0/auth0-react";

const Hero = () => {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? <HeroHome /> : <HeroLogin />;
};

export default Hero;
