import  EventTable  from '../components/events/EventTable'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Container } from "@chakra-ui/react"
import Loading2 from '../components/misc/loading-2'
import { usePermissionState } from '../context/PermissionContext'

const Events = () => {
  const { userPermission } = usePermissionState()

  if (userPermission === null) {
    return (
      <Container display="flex" alignItems="center" justifyContent="center" height="100vh">
        <Loading2 />
      </Container>
    )
  }

  if (!userPermission || userPermission.camera !== true) {
    return (
      <Container display="flex" alignItems="center" justifyContent="center" height="100vh">
        <h1 size={30} weight="bold">
          No tienes permisos para ver esta página
        </h1>
      </Container>
    );
  }
  return (
    <div className="table-container">
      <EventTable />
    </div>
  )
}

export default withAuthenticationRequired(Events)
