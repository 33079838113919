import { Skeleton, SkeletonCircle, Box } from '@chakra-ui/react'
const TableEvents = () => {
    return (
        <Box padding='5' width="100%" display="flex" gap="10px" flex-direction='row' alignItems="center" justifyContent="space-between" boxShadow='lg' bg='white'>
            <SkeletonCircle size='6'/>
            <Skeleton height='20px'  width="10%" marginLeft="10%"/>
            <Skeleton height='20px'  width="20%" />
            <Skeleton height='20px'  width="8%" marginRight="6%"/>
        </Box>
    );
}

export default TableEvents;