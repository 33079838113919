export const getColor = (value) => {
  // Mapea cada número a un color diferente
  value.replace(/'/g, '')

  const colorMap = {
    'Person': '#0BC5EA', // Color para PERSONA (cyan)
    'Car': '#ED8936', // Color para AUTO (orange)
    'Truck': '#E53E3E', // Color para CAMIONETA (red)
  }

  // Crea una matriz de colores basada en los números en el campo Event
  const color = colorMap[value]

  return color
}
