import { withAuthenticationRequired } from '@auth0/auth0-react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Loading2 from '../components/misc/loading-2'
import { Box, Container } from '@chakra-ui/react'
import { TableSwitcher } from '../components/data/TableSwitcher'
import { usePermissionState } from '../context/PermissionContext'

const Data = () => {
const { userPermission, isLoading } = usePermissionState()

  if (isLoading || userPermission === null) {
    return (
      <Container display="flex" alignItems="center" justifyContent="center" height="100vh">
        <Loading2 />
      </Container>
    )
  }

  if (!userPermission || userPermission.device !== true) {
    return (
      <Container display="flex" alignItems="center" justifyContent="center" height="100vh">
        <h1 size={30} weight="bold">
          No tienes permisos para ver esta página
        </h1>
      </Container>
    )
  }

  return (
    <Box aria-labelledby="data" height="100vh" marginTop={{ base: "10px", md: "20px", lg: "20px" }} marginLeft={{ base: "5px", md: "20px", lg: "30px" }} marginRight={{ base: "5px", md: "20px", lg: "30px" }}>
      <TableSwitcher  />
    </Box>
  )
}

export default withAuthenticationRequired(Data)
