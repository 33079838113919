import { Skeleton,Flex } from '@chakra-ui/react'
const AccordionEvents = () => {
    return (
        <Flex padding='0' width="100%" gap="10px" flexDirection="column" alignItems="start" justifyContent="space-between" bg='white'>
            <Skeleton height='10px'  width="10%" marginLeft="0%"/>
            <Skeleton height='25px'  width="20%" />
        </Flex>
    );
}

export default AccordionEvents;