import React from 'react'

const Burger = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="50"
      viewBox="0 0 24 24"
    >
      <path
        fill="none"
        stroke="#1e87fa"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 17h18M3 12h18M3 7h18"
      />
    </svg>
  )
}

export default Burger
