import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Flex,
  Box,
  Spinner,
  Text,
  Stack,
  Icon,
  Divider,
  Alert,
  AlertDescription,
} from '@chakra-ui/react'
import { useAuth0 } from '@auth0/auth0-react'
import { SignalBars } from './SignalBars'
import VisualizationCard from '../shared/VisualizationCard'
import Update from '../Iconsjsx/Update'
import Gear from '../Iconsjsx/Gear'
import { useHeatmapContext } from '../../context/HeatmapContext'
import { useEffect, useState } from 'react'
import { getProtectedResource } from '../../auth/auth-fetch'
import { ADDITIONAL_STATUS_DESCRIPTION } from '../../utils/heatmap'
import { TbBellCheck } from "react-icons/tb";
import AlarmTable from './AlarmTable'
import { GoAlert } from "react-icons/go";

const ModalBodyOwnerInfo = ({ selectedDevice }) => {
  const hasService = selectedDevice?.active_service?.type === 'service'
  const firstTermTitle = (<b className="title-modal">{hasService ? `Service Co.: ` : `Owner: `}</b>)
  const secondTermTitle = (<b className="title-modal">{hasService ? `Operator Co.: ` : ''}</b>)
  const firstTerm = hasService
    ? `${selectedDevice?.active_service?.serviceAccount?.name}` : ` ${selectedDevice?.active_service?.accountCreator?.name} `

  const secondTerm = hasService ? `${selectedDevice?.active_service?.operatorAccount?.name}` : ''

  const signal = Number(selectedDevice?.job_days) >= 0 && (
    <Stack direction={"row"} ml={2}>
      <SignalBars signalData={selectedDevice.signal_bivo} />
    </Stack>
  )
  return (<>
    {firstTermTitle}
    <span style={{ marginLeft: '0.25rem', marginRight: '0.25rem' }}>{`${firstTerm} ${secondTerm && `|`}`}</span>
    {secondTermTitle}
    <span>{`${secondTerm}`}</span>
    {signal}
  </>)
}

const CustomModal = ({ isOpen, onClose }) => {
  const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE
  const URL_BACK = process.env.REACT_APP_URL_BACK
  const { user, getAccessTokenSilently } = useAuth0()
  const {
    selectedDevice,
    setSelectedDevice,
  } = useHeatmapContext()
  const [topicData, setTopicData] = useState([])
  const [topicList, setTopicList] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (selectedDevice?.serial) {
      setIsLoading(true)
      const fetchTopicData = async () => {
        try {
          const token = await getAccessTokenSilently({
            audience: AUDIENCE,
          })
          const urlFetch = `${URL_BACK}/api/topics/${user?.sub}?serial=${selectedDevice?.serial}`
          const { data } = await getProtectedResource(urlFetch, token)

          setTopicData(data)
          setTopicList(data.map((topic) => topic.topic))
          setIsLoading(false)
        } catch (error) {
          console.error(error)
          setIsLoading(false)
        }
      }
      fetchTopicData()
    }
  }, [selectedDevice])

  const handleDashboardClick = (url) => {
    window.location.href = url
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setSelectedDevice(null)
        setTopicData([])
        setTopicList([])
        setIsLoading(true)
        onClose()
      }}
      mx="5px"
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent width="850px" maxWidth="100%" mx="5px" borderRadius="16px">
        <ModalHeader>
          <Stack direction={"row"} justify={"space-between"}>
            <Text fontSize={"2xl"} fontWeight={"bold"}>{selectedDevice?.active_service?.type === 'service' ? selectedDevice?.active_service?.name : ''} {selectedDevice?.device_model?.name} {selectedDevice?.serial}</Text>
          </Stack>
        </ModalHeader>
        <ModalBody pt="0">

          <Flex flexDirection="row" alignItems="center" gap="10px">
            <Box
              as="p"
              fontSize={14}
              marginBottom={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              color="#718096"
            >
              <ModalBodyOwnerInfo selectedDevice={selectedDevice} />
            </Box>
          </Flex>
          {selectedDevice?.job_days >= 0 && (
            <Box
              as="p"
              fontSize={18}
              marginBottom={0}
              display="flex"
              color="#718096"
            >
              Job Days: {selectedDevice?.job_days}
            </Box>
          )}
          {selectedDevice?.additional_statuses?.length ? (
            <Box
              as="p"
              fontSize={16}
              marginBottom={0}
              display="flex"
              color="#718096"
            >
              <Alert
                status='info'
                variant='left-accent'
                background='gray.200'
                borderLeftColor="gray.400"
              >
                <Icon as={GoAlert} />
                <Box ml={4}>
                  <AlertDescription>
                    {selectedDevice?.additional_statuses?.map(status => (
                      <Text mb={0}>
                        {`- ${ADDITIONAL_STATUS_DESCRIPTION[status] || ''}`}
                      </Text>
                    ))}
                  </AlertDescription>  
                </Box>
              </Alert>
            </Box>
          ): null}
          <div className="modal-description">
            {isLoading ? (
              <Stack width={"100%"} direction={"row"} justifyContent={"center"} mt={2}>
                <Spinner color={"primary.500"} />
              </Stack>
            ) : (
              <Box>
                {selectedDevice?.device_model?.name === 'mSand' ? (
                  <Flex
                    flexDirection="row"
                    gap="10px"
                    justifyContent="start"
                    alignItems="center"
                  >
                    {topicList.includes('RIG_MODE') && (
                      <VisualizationCard
                        info={
                          topicData.find((topic) => topic.topic === 'RIG_MODE')
                            ?.description
                        }
                        color="#1c87fa"
                        title="Rig Mode"
                        icon={<Gear classNames="icon-card" />}
                        bgIcon="linear-gradient(270deg, rgb(30, 135, 250) 0%, rgb(111, 175, 246) 100%)"
                      />
                    )}
                    {topicList.includes('STATUS') && (
                      <VisualizationCard
                        info={
                          topicData.find((topic) => topic.topic === 'STATUS')
                            ?.description
                        }
                        title="General Status"
                      />
                    )}
                    {topicList.includes('OPMODE') && (
                      <VisualizationCard
                        info={
                          topicData.find((topic) => topic.topic === 'OPMODE')
                            ?.description
                        }
                        color="#1c87fa"
                        title="Operation Mode"
                        icon={<Update classNames="icon-card" />}
                        bgIcon="linear-gradient(270deg, rgb(30, 135, 250) 0%, rgb(111, 175, 246) 100%)"
                      />
                    )}
                  </Flex>
                ) : selectedDevice?.device_model?.name === 'EHC' ? (
                  <Flex
                    flexDirection="row"
                    gap="10px"
                    justifyContent="start"
                    alignItems="center"
                  >
                    {topicList.includes('VALVE_STATUS') && (
                      <VisualizationCard
                        info={
                          topicData.find(
                            (topic) => topic.topic === 'VALVE_STATUS'
                          )?.description
                        }
                        color="rgb(56, 161, 105)"
                        title="Valve Status"
                        icon={<Gear classNames="icon-card" />}
                        bgIcon="linear-gradient(90deg, rgb(106, 211, 155) 0%, rgb(56, 161, 105) 100%)"
                      />
                    )}
                    {topicList.includes('OPMODE') && (
                      <VisualizationCard
                        info={
                          topicData.find((topic) => topic.topic === 'OPMODE')
                            ?.description
                        }
                        color="#1c87fa"
                        title="Operation Mode"
                        icon={<Update classNames="icon-card" />}
                        bgIcon="linear-gradient(270deg, rgb(30, 135, 250) 0%, rgb(111, 175, 246) 100%)"
                      />
                    )}
                  </Flex>
                ) : selectedDevice?.device_model?.name === 'SWD' ? (
                  <Flex
                    flexDirection="row"
                    gap="10px"
                    justifyContent="start"
                    alignItems="center"
                  >
                    {topicList.includes('STATUS') && (
                      <VisualizationCard
                        info={
                          topicData.find((topic) => topic.topic === 'STATUS')
                            ?.description
                        }
                        title="Status"
                      />
                    )}
                    {topicList.includes('TANK_STATUS') && (
                      <VisualizationCard
                        info={
                          topicData.find(
                            (topic) => topic.topic === 'TANK_STATUS'
                          )?.description
                        }
                        title="Tank Status"
                      />
                    )}
                  </Flex>
                ) : selectedDevice?.device_model?.name === 'mSafe' ? (
                  <Flex
                    flexDirection="row"
                    gap="10px"
                    justifyContent="start"
                    alignItems="center"
                  >
                    {topicList.includes('VALVE_STATUS') && (
                      <VisualizationCard
                        info={
                          topicData.find(
                            (topic) => topic.topic === 'VALVE_STATUS'
                          )?.description
                        }
                        color="rgb(56, 161, 105)"
                        title="Valve Status"
                        icon={<Gear classNames="icon-card" />}
                        bgIcon="linear-gradient(90deg, rgb(106, 211, 155) 0%, rgb(56, 161, 105) 100%)"
                      />
                    )}
                    {topicList.includes('OPMODE') && (
                      <VisualizationCard
                        info={
                          topicData.find((topic) => topic.topic === 'OPMODE')
                            ?.description
                        }
                        color="#1c87fa"
                        title="Operation Mode"
                        icon={<Update classNames="icon-card" />}
                        bgIcon="linear-gradient(270deg, rgb(30, 135, 250) 0%, rgb(111, 175, 246) 100%)"
                      />
                    )}
                  </Flex>
                ) : selectedDevice?.device_model?.name === 'mSafe3' ? (
                  <Flex
                    flexDirection="row"
                    gap="10px"
                    justifyContent="start"
                    alignItems="center"
                  >
                    {topicList.includes('GEN_STAT') && (
                      <VisualizationCard
                        info={
                          topicData.find(
                            (topic) => topic.topic === 'GEN_STAT'
                          )?.description
                        }
                        color="rgb(56, 161, 105)"
                        title="General Status"
                        icon={<Gear classNames="icon-card" />}
                        bgIcon="linear-gradient(90deg, rgb(106, 211, 155) 0%, rgb(56, 161, 105) 100%)"
                      />
                    )}
                    {topicList.includes('OPER_MODE') && (
                      <VisualizationCard
                        info={
                          topicData.find((topic) => topic.topic === 'OPER_MODE')
                            ?.description
                        }
                        color="#1c87fa"
                        title="Operational Mode"
                        icon={<Update classNames="icon-card" />}
                        bgIcon="linear-gradient(270deg, rgb(30, 135, 250) 0%, rgb(111, 175, 246) 100%)"
                      />
                    )}
                    {topicList.includes('X_220') && (
                      <VisualizationCard
                        info={
                          topicData.find((topic) => topic.topic === 'X_220')
                            ?.description
                        }
                        color="#1c87fa"
                        title="Line Voltage"
                        icon={<Update classNames="icon-card" />}
                        bgIcon="linear-gradient(270deg, rgb(30, 135, 250) 0%, rgb(111, 175, 246) 100%)"
                      />
                    )}
                  </Flex>
                ) : null}
              </Box>
            )}

            <Divider />
            {selectedDevice?.alarms?.length !== 0 ? (
              <Stack>
                <AlarmTable alarmsData={selectedDevice?.alarms || []} selectedDevice={selectedDevice} />
              </Stack>
            ) : (
              <Stack width={"100%"} height={"60px"} justifyContent={"center"} alignItems={"center"} mt={10}>
                <Icon as={TbBellCheck} boxSize={6} color={"gray.300"} />
                <Text color={"gray.300"} fontSize='md'>No active alarms</Text>
              </Stack>
            )}
          </div>
          <Divider />
          <Flex flexDirection="row" alignItems="center" justifyContent="space-between" w={"100%"} mt={10}>
            <Flex flexDirection="reverse-row" gap={4} >
              <Button
                style={{
                  padding: '10px',
                  backgroundColor: '#1E87FA',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '14px',
                  cursor: 'pointer',
                  border: '1px solid #1E87FA',
                  position: 'relative',
                  marginBottom: '16px',
                  color: '#ffffff',
                }}
                isDisabled={selectedDevice?.active_service?.type !== 'service'}
                onClick={() => {
                  handleDashboardClick(
                    `${process.env.REACT_APP_MSUITE_URL}/app/account/devices/${selectedDevice.id}/dashboard?tab=0&service=${selectedDevice?.active_service?.id}`
                  )
                }}
              >
                Dashboard
              </Button>
              <Button
                style={{
                  padding: '10px',
                  backgroundColor: '#ffffff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '14px',
                  cursor: 'pointer',
                  border: '1px solid #1E87FA',
                  position: 'relative',
                  marginBottom: '16px',
                  color: '#1E87FA',
                }}
                onClick={() => {
                  setSelectedDevice(null)
                  setTopicData([])
                  setTopicList([])
                  setIsLoading(true)
                  onClose()
                }}
              >
                Close
              </Button></Flex>


            {selectedDevice?.last_connection &&
              (
                <Box
                  as="p"
                  fontSize={14}
                  marginBottom={0}
                  display="flex"
                  color="#718096"
                >
                  Last Analysis: {selectedDevice?.last_connection.slice(0, 10)}
                </Box>
              )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CustomModal