import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Checkbox,
  Button,
  Input,
  InputGroup,
} from '@chakra-ui/react'
import { useState } from 'react'
import { MagnifyingGlassIcon } from '../Iconsjsx/search'
import ColumnFilters from '../Iconsjsx/columnFilters'
import AccordionEvents from '../Skeletons/events/accordionEvents'

export const TableAccordion = ({ setEventFilter, eventFilter, isLoading }) => {
  const [tagsSelected, setTagSelected] = useState(['Person', 'Car', 'Truck'])
  const [cameraTypes, setCameraTypes] = useState(['Cam01 Avalos', 'Cam01 Seminole', 'Cam02 Seminole' ])
  const [plateFilter, setPlateFilter] = useState('')

  const handleCameraSelect = (event) => {
    const checkedCameraName = event.target.value;
    setEventFilter((prevFilters) => ({
      ...prevFilters,
      camFilter: prevFilters.camFilter.includes(checkedCameraName)
        ? prevFilters.camFilter.filter((name) => name !== checkedCameraName)
        : [...prevFilters.camFilter, checkedCameraName],
    }));
  };

  const handleTagSelect = (event) => {
    const checkedTagName = event.target.value;
    setEventFilter((prevFilters) => ({
      ...prevFilters,
      tagFilter: prevFilters.tagFilter.includes(checkedTagName)
        ? prevFilters.tagFilter.filter((name) => name !== checkedTagName)
        : [...prevFilters.tagFilter, checkedTagName],
    }));
  };

  const handlePlateSearch = () => {
    setEventFilter((prevFilters) => ({
      ...prevFilters,
      plateFilter
    }))
  };

  const handleChange = (event) => {
    setPlateFilter(event.target.value);
  };

  const clearFilter = () => {
    setEventFilter({
      camFilter: [],
      dateFilter: { startDate: '', endDate: '' },
      plateFilter: '',
      tagFilter: []
    });
  };

  return (
    <Accordion allowMultiple marginY="20px" marginX="5px">
      <AccordionItem border="none">
        <AccordionButton
          bgColor="#F6FAFC"
          rounded="base"
        >
          <Box
            as="span"
            display="flex"
            gap="5px"
            flexDirection="row"
            justifyContent="start"
            alignItems="center"
            flex="1"
            textAlign="left"
          >
            Filters
            <ColumnFilters />
          </Box>
          <AccordionIcon />
        </AccordionButton>
        {!isLoading ?
          <AccordionPanel
            pb={4}
            display="flex"
            justifyContent="start"
            alignItems="start"
            flexDirection="column"
            paddingTop="30px"
            paddingBottom="10px"
            px="20px"
            gap="10px"
          >
            <label className="mb-0 text-sm fw-bold">Select a Camera</label>
            <Box
              display="grid"
              width="100%"
              gridAutoRows="1fr"
              gridTemplateColumns="repeat(2, 1fr)"
              justifyContent="space-evenly"
              alignItems="center"
            >
              {cameraTypes && cameraTypes.map((camera, index) => (
                <Checkbox
                  key={`camera-${index}`}
                  value={camera}
                  isChecked={eventFilter.camFilter.includes(camera)}
                  onChange={handleCameraSelect}
                >
                  {camera}
                </Checkbox>
              ))}
            </Box>
          </AccordionPanel>
          :
          <AccordionPanel
            pb={4}
            display="flex"
            justifyContent="start"
            alignItems="start"
            flexDirection="column"
            paddingTop="30px"
            paddingBottom="10px"
            px="20px"
            gap="10px"
          >
            <AccordionEvents />
          </AccordionPanel>
        }
        <AccordionPanel
          pb={4}
          display="flex"
          justifyContent="start"
          alignItems="start"
          flexDirection="column"
          py="10px"
          px="20px"
          gap="8px"
        >
          <label className="mb-0 text-sm fw-bold" htmlFor="plateSearch">
            Search by License Plate:
          </label>
          <InputGroup size="md">
            <Input
              type="text"
              variant="outline"
              name="plateSearch"
              placeholder="Enter License Plate"
              onChange={handleChange}
            // value={eventFilter.plateFilter}
            />
            {/* <InputRightElement> */}
            <Button
              colorScheme="primary"
              fontSize="16px"
              py="2"
              px="10"
              onClick={handlePlateSearch}
            >
              <MagnifyingGlassIcon />
            </Button>
            {/* </InputRightElement> */}
          </InputGroup>
        </AccordionPanel>
        <AccordionPanel
          pb={4}
          display="flex"
          justifyContent="start"
          alignItems="start"
          flexDirection="column"
          py="10px"
          px="20px"
          gap="8px"
        >
          <label className="mb-0 text-sm fw-bold" htmlFor="startDate">
            Start Date:
          </label>
          <Input
            type="date"
            name="startDate"
            placeholder="Start Date"
            value={eventFilter.dateFilter.startDate || ''}
            onChange={(e) =>
              setEventFilter((prevFilters) => ({
                ...prevFilters,
                dateFilter: { ...prevFilters.dateFilter, startDate: e.target.value },
              }))
            }
          />
          <label className="mb-0 text-sm fw-bold" htmlFor="endDate">
            End Date:
          </label>
          <Input
            type="date"
            name="endDate"
            placeholder="End Date"
            value={eventFilter.dateFilter.endDate || ''}
            onChange={(e) =>
              setEventFilter((prevFilters) => ({
                ...prevFilters,
                dateFilter: { ...prevFilters.dateFilter, endDate: e.target.value },
              }))
            }
          />
          <Button
            onClick={clearFilter}
            size="sm"
            marginY="10px"
            colorScheme="primary"
            variant="outline"
          >
            Clear Filters
          </Button>
          <div className="table-caption">
            {tagsSelected && tagsSelected.map((tag, index) => (
              <div className="circle-container-caption" key={`tag-${index}`}>
                <Checkbox
                  value={tag}
                  isChecked={eventFilter.tagFilter.includes(tag)}
                  onChange={(e) => handleTagSelect(e)}
                >
                  <p className="m-0 ml-5">
                    <strong>{tag}</strong>
                  </p>
                </Checkbox>
              </div>
            ))}
          </div>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
