import React, { useEffect } from 'react';
import { useToast, Wrap } from '@chakra-ui/react';

const Toast = ({ title, status, message, showError, delay  }) => {
  const toast = useToast();

  useEffect(() => {
    const showToast = () => {
      let toastProps = {
        title: `${title}`,
        status: status,
        duration: 8000,
        isClosable: true,
      };

      switch (status) {
        case 'error':
          toastProps = {
            ...toastProps,
            description: `${message}`,
            position: 'top-right',
          };
          break;
        case 'warning':
          toastProps = {
            ...toastProps,
            description: `${message}`,
            position: 'top-right',
          };
          break;
        case 'success':
          toastProps = {
            ...toastProps,
            description: `${message}`,
            position: 'top-right',
          };
          break;

        default:
          break;
      }

      toast(toastProps);
    };

    // Mostrar el toast cuando showError sea true con un retraso
    if (showError) {
      setTimeout(() => {
        showToast();
      }, delay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, message, toast, showError]);

  return <Wrap position="absolute"/>;
};

export default Toast;
