import React, { useState } from 'react'
import { Popover, PopoverTrigger, PopoverContent, PopoverBody, Text } from '@chakra-ui/react'


export const DetailPopover = ({ alarm }) => {
  const [isOpenPopover, setIsOpenPopover] = useState(false)

  return (
    <Popover
      isOpen={isOpenPopover}
      onClose={() => setIsOpenPopover(false)}
    >
      <PopoverTrigger>
        <Text
          onMouseEnter={() => setIsOpenPopover(true)}
          onMouseLeave={() => setIsOpenPopover(false)}
          p={0}
          marginBottom={0} 
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          maxWidth="300px"
          fontSize={'13px'}
        >
          {alarm.description}
        </Text>
      </PopoverTrigger>
      <PopoverContent width="fit-content" mb={0}>
        <PopoverBody>
          <Text m={0} p={0} fontSize={'13px'}>
            {alarm.description}
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

