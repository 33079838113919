import { ThemeProvider, createTheme } from '@mui/material/styles';
import AlarmViewerTable from './AlarmViewer.table';

const muiTheme = createTheme();
export const AlarmViewerProvider = () => {
  return (
    <ThemeProvider theme={muiTheme}>
      <AlarmViewerTable/>
    </ThemeProvider>
  );
};