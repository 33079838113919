import {
  Flex,
  Text
} from '@chakra-ui/react'
export const SignalBars = ({signalData}) => {

  const determineSignalLevel = (inputLevel) => {
    if (inputLevel >= 0 && inputLevel <= 9) {
      return {stg: 0, level: "No signal"};
    } else if (inputLevel >= 10 && inputLevel <= 13) {
      return {stg: 1, level: "Poor"};
    } else if (inputLevel >= 14 && inputLevel <= 18) {
      return {stg: 2, level: "Fair"};
    } else if (inputLevel >= 19 && inputLevel <= 24) {
      return {stg: 3, level: "Good"};
    } else if (inputLevel >= 25 && inputLevel <= 31) {
      return {stg: 4, level: "Excellent"};
    } else {
      return 'Not detectable';
    }
  };
  const strength = determineSignalLevel(signalData);

  const barHeights = [2, 5, 8, 15, 20]; 

  return (
    <Flex flexDirection="row" height="100%" marginLeft="5px" alignItems={"center"} color="#718096">
      <b className="title-modal">Comm signal:</b>
      <div className="signal-container">
        {barHeights.map((height, index) => {
          if (index <= strength.stg) {
            return <div key={index} className={`signal-bar active strength-${strength.stg}`} style={{ height: height + 'px' }}></div>;
          } else {
            return null;
          }
        })}
      </div>
      <Text fontSize="12" marginBottom="0" marginTop="4px">({strength.level})</Text>
    </Flex>
  );
};