import MSuiteMini from '../Iconsjsx/msuitemini'
import LogoExpanded from '../Iconsjsx/LogoExpanded'
import { MicroChip } from '../Iconsjsx/microChip'
import {
  Box,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom';
import ScreenIcon from '../Iconsjsx/ScreenIcon'
import ArrowOpen from '../Iconsjsx/arrow-open'
import ArrowExit from '../Iconsjsx/arrow-exit'
import MsuiteButton from './mSuite-button'
import LogoutButton from './logout-button'
import { useDeskNavState } from '../../context/DeskNavContext'
import { usePermissionState } from '../../context/PermissionContext';

export const DeskNav = () => {
  const { userPermission } = usePermissionState()
  const { isExpanded, toggleIsExpanded, currentPage, changePage } = useDeskNavState()
  return (
    <Box className={`${isExpanded ? 'expanded' : ''} desk-nav-color z-99`}>
      <Box
        as="nav"
        className={!isExpanded ? 'desk-nav' : 'desk-nav expanded'}
      >
        <Box
          as="div"
          marginLeft="20px"
          height="100vh"
          width={!isExpanded ? '45px' : '257px'}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          {/* flechita p abrir */}
          <Box
            as="div"
            marginTop="20px"
            width="100%"
          >
            <Button
              justifyContent="space-between"
              colorScheme="white"
              onClick={toggleIsExpanded}
              marginLeft={!isExpanded ? -15 : -59}
              alignItems="center"
              px={'38%'}
            >
              <Box
                marginRight={!isExpanded ? 2 : 112}
                marginLeft={isExpanded && -2}
                flexDirection='row !important'
              >

                {!isExpanded ? <MSuiteMini /> : <LogoExpanded />}
              </Box>
              {isExpanded ? <ArrowExit /> : <ArrowOpen />}
            </Button>
          </Box>

          {userPermission && userPermission.device === true && !isExpanded && (
            <Box paddingTop="20px" height="86px" onClick={toggleIsExpanded} cursor="pointer">
              <Box py="8px" className={`${currentPage === 'VA' ? 'current-link' : ''} menu-buttons`}>
                <div className="icon-bg">
                  <MicroChip className="icon-menu" />
                </div>
              </Box>
            </Box>
          )}
          { userPermission && userPermission.device === true  && isExpanded && (
            <Accordion allowMultiple maxWidth="220px" width="100%" border="transparent" paddingTop="20px">
              <AccordionItem >
                <AccordionButton className={`${currentPage === 'VA' ? 'current-link' : ''} menu-buttons`} display="flex" alignItems="center" width="100%" justifyContent="space-around" _expanded={{ boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)", bg: "white !important" }}>
                  <div className="icon-bg">
                    <MicroChip className="icon-menu" />
                  </div>
                  <Box marginLeft="3" marginRight="0" marginBottom="0" color="rgba(0, 0, 0, 0.48)" fontWeight="500" fontSize="16px" className="menu-title">
                    Virtual Assistant
                  </Box>
                  <AccordionIcon color={'rgba(0, 0, 0, 0.48)'} marginLeft="4px" />


                </AccordionButton>
                <AccordionPanel  >
                  { userPermission && userPermission.device === true &&
                  <>
                    <Box
                    as="div"
                    py={2}
                    textColor={'rgba(0, 0, 0, 0.48)'}
                    fontSize={15}
                  >
                    <Link
                      px="10"
                      py={2}
                      to="/device"
                      fontSize={'1rem'}
                      className="desk-nav-link"
                      onClick={() => { changePage('VA'); toggleIsExpanded(); }}
                    >
                      Devices
                    </Link>
                  </Box>
                  </>
                  }
                  { userPermission && userPermission.device === true &&
                  <>
                    <Box
                    as="div"
                    py={2}
                    textColor={'rgba(0, 0, 0, 0.48)'}
                    fontSize={15}
                  >
                    <Link
                      className="desk-nav-link"
                      px="10"
                      py={2}
                      to="/data"
                      fontSize={'1rem'}
                      onClick={() => { changePage('VA'); toggleIsExpanded(); }}
                    >
                      Alarms
                    </Link>
                  </Box>
                  </>
                  }

                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}

          {userPermission && userPermission.camera === true && !isExpanded && (
            <Box paddingTop="0px" height="86px" onClick={toggleIsExpanded} cursor="pointer">
              <Box py="8px" className={`${currentPage === 'VAN' ? 'current-link' : ''} menu-buttons`}>
                <div className="icon-bg">
                  <ScreenIcon className="icon-menu" />
                </div>
              </Box>
            </Box>
          )}
          {userPermission && userPermission.camera === true && isExpanded && (
            <Accordion allowMultiple maxWidth="220px" width="100%" border="transparent" py="20px">
              <AccordionItem >
                <AccordionButton className={`${currentPage === 'VAN' ? 'current-link' : ''} menu-buttons`} display="flex" alignItems="center" width="100%" justifyContent="space-around" _expanded={{ boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)", bg: "white !important" }}>
                  <div className="icon-bg">
                    <ScreenIcon className="icon-menu" />
                  </div>
                  <Box marginLeft="3" marginRight="0" marginBottom="0" color="rgba(0, 0, 0, 0.48)" fontWeight="500" fontSize="16px" className="menu-title">
                    Video Analytics
                  </Box>
                  <AccordionIcon color={'rgba(0, 0, 0, 0.48)'} marginLeft="4px" />


                </AccordionButton>
                <AccordionPanel>
                  <Box
                    py={2}
                    as="div"
                    textColor={'rgba(0, 0, 0, 0.48)'}
                    fontSize={15}
                  >
                    <Link
                      px="10"
                      py={2}
                      to="/camera"
                      fontSize={'1rem'}
                      className="desk-nav-link"
                      onClick={() => { changePage('VAN'); toggleIsExpanded(); }}
                    >
                      Cameras
                    </Link>
                  </Box>
                  <Box py={2} textColor={'rgba(0, 0, 0, 0.48)'} fontSize={15}>
                    <Link
                      className="desk-nav-link"
                      px="10"
                      py={2}
                      to="/events"
                      fontSize={'1rem'}
                      onClick={() => { changePage('VAN'); toggleIsExpanded(); }}
                    >
                      Events
                    </Link>
                  </Box>
                  <Box py={2} textColor={'rgba(0, 0, 0, 0.48)'} fontSize={15}>
                    <Link
                      className="desk-nav-link"
                      px="10"
                      py={2}
                      to="/diagnostic"
                      fontSize={'1rem'}
                      onClick={() => { changePage('VAN'); toggleIsExpanded(); }}
                    >
                      Diagnostic
                    </Link>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}

        </Box>
        <Box display="flex" flexDirection="column" justifyContent="flex-start" alignSelf="center">
          <MsuiteButton label={isExpanded && 'Return to mSuite'} />
          <LogoutButton label={isExpanded && 'Sign Out'} />
        </Box>
      </Box>
    </Box>


  )
}