import { useAuth0 } from '@auth0/auth0-react'
import { getProtectedResource } from '../auth/auth-fetch'
import { createContext, useContext, useState, useEffect } from 'react';

const PermissionContext = createContext();

export const usePermissionState = () => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error('usePermissionState must be used within a PermissionProvider');
  }
  return context;
}

export const PermissionProvider = ({ children }) => {
  const [userData, setUserData] = useState(null)
  const [userPermission, setUserPermission] = useState(null)
  const { user, isLoading, getAccessTokenSilently } = useAuth0()

  const URL_BACK = process.env.REACT_APP_URL_BACK
  const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE

  useEffect(() => {
    if (!isLoading) {
      (async () => {
        try {
          const token = await getAccessTokenSilently({
            authorizationParams: {
              audience: AUDIENCE,
            }
          })

          const urlFetch = `${URL_BACK}/api/user-permission/${user?.sub}`;
          const { data } = await getProtectedResource(urlFetch, token);
          if (data) {
            //const PermissionAll = data.some(function(item) { return item.permission_name === "VIRTUAL_ASSISTANT";});
            const cameraExists = data.some(function(item) { return item.permission_name === "VIRTUAL_ASSISTANT_CAMERA";});
            const deviceExists = data.some(function(item) { return item.permission_name === "VIRTUAL_ASSISTANT_DEVICE";});
            const deviceSeenExists = data.some(function(item) { return item.permission_name === "VIRTUAL_ASSISTANT_DEVICE_SEEN";});
            setUserPermission({camera: cameraExists, device: deviceExists, device_seen: deviceSeenExists});
            setUserData(data);
          } else {
            setUserPermission(false);
            setUserData(false);
          }
        } catch (err) {
          console.error(err)
        }
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);



  return (
    <PermissionContext.Provider value={{ userData, userPermission, isLoading }}>
      {children}
    </PermissionContext.Provider>
  );
};