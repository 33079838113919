// import React, { Component } from 'react'
// import Chart from 'chart.js/auto'

// class BarChart extends Component {
//   chartRef = React.createRef() // TIRA ERROR

//   componentDidMount() {
//     this.buildChart()
//   }

//   interpolateData(data, numPoints) {
//     const interpolatedData = []
//     const step = data.length / numPoints

//     for (let i = 0; i < data.length; i += step) {
//       const startIdx = Math.floor(i)
//       const endIdx = Math.min(Math.ceil(i + step), data.length)
//       const sum = data
//         .slice(startIdx, endIdx)
//         .reduce((acc, val) => acc + val, 0)
//       const average = sum / (endIdx - startIdx)
//       interpolatedData.push(average)
//     }

//     return interpolatedData
//   }

//   buildChart() {
//     const myChartRef = this.chartRef.current.getContext('2d')
//     const { datosLinea1, datosLinea2, datosLinea3, datosLinea4 } = this.props

//     // Apply interpolation with 100 interpolated points for smoother visualization
//     //const interpolatedDatosLinea1 = this.interpolateData(datosLinea1, 300);
//     //const interpolatedDatosLinea2 = this.interpolateData(datosLinea2, 300);
//     //const interpolatedDatosLinea3 = this.interpolateData(datosLinea3, 300);

//     new Chart(myChartRef, {
//       type: 'line', // Line chart
//       data: {
//         labels: Array.from({ length: datosLinea4.length }, (_, i) => i + 1), // Labels from 1 to 100
//         datasets: [
//           {
//             label: 'Presion de cabeza',
//             data: datosLinea1,
//             borderColor: 'rgba(255, 99, 132, 1)',
//             fill: false,
//           },
//           {
//             label: 'Presion de linea',
//             data: datosLinea2,
//             borderColor: 'rgba(54, 162, 235, 1)',
//             fill: false,
//           },
//           {
//             label: 'Dif',
//             data: datosLinea3,
//             borderColor: 'rgba(75, 192, 192, 1)',
//             borderDash: [10, 20], // Línea punteada
//             fill: false,
//           },
//           {
//             label: 'Estado',
//             data: datosLinea4,
//             borderColor: 'rgba(153, 102, 255, 1)',
//             yAxisID: 'right-y-axis', // Utiliza el eje Y a la derecha
//             fill: false,
//           },
//         ],
//       },
//       options: {
//         scales: {
//           y: [
//             {
//               position: 'right', // Eje Y a la izquierda (por defecto)
//               beginAtZero: true,
//             },
//             {
//               id: 'right-y-axis', // Identificador del eje Y a la derecha
//               position: 'right', // Eje Y a la derecha
//               beginAtZero: true,
//             },
//           ],
//           x: {
//             position: 'bottom', // Etiquetas en la parte superior
//           },
//         },
//         plugins: {
//           legend: {
//             display: true,
//             position: 'bottom', // Coloca la leyenda debajo del gráfico
//           },
//         },
//       },
//     })
//   }

//   render() {
//     return (
//       <div style={{ width: '100%', height: '700px' }}>
//         <canvas ref={this.chartRef} />
//       </div>
//     )
//   }
// }

// export default BarChart
