import { useState } from 'react'
import {
  Box,
  Flex,
  Button,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
} from '@chakra-ui/react'

export const FilterDropdown = ({
  title,
  selectedFilter,
  options,
  handleSelect,
}) => {
  const [allChecked, setAllChecked] = useState(false);

  const handleChecked = () => {
    const e = {
      target: { value: "all", checked: !allChecked},
    };
    handleSelect(e);
    setAllChecked(!allChecked)
  }

  const showSelected = () => {
    if (selectedFilter.length === options.length || selectedFilter.includes('All')) {
      return 'All'
    }

    return selectedFilter.length > 0
      ? selectedFilter.length > 3
        ? `Selected: ${selectedFilter.length}`
      : selectedFilter.map((filter) => filter?.slice(0, 5)).join(', ') : 'All'
  };


  return (
    <Menu closeOnSelect={false}>
        <Box as="div" lineHeight={"32px"} fontWeight="600" fontSize={12}>{title}:</Box>
        <MenuButton
          as={Button}
          height={'30px'}
          marginLeft={'4px'}
          border={'1px solid #E2E8F0'}
          backgroundColor={'white'}
          color={'#535760'}
          _expanded={{ bg: '#1C87FA', color: 'white'}}
        >
          <Box
            as="p"
            fontWeight={500}
            marginBottom={0}
            fontSize={12}
          >
            {showSelected()}
          </Box>
        </MenuButton>


      <MenuList maxHeight="400px" overflowY="auto" maxWidth={"auto"} overflowX="hidden">
        {/* All options selected */}
        <Box as="p" m="0" p="0" h="100%" w="100%" ml={4}>
            <Button variant="link" onClick={handleChecked}>
              {!allChecked ? "Select all" : "Deselect all"}
            </Button>
          </Box>
        {options &&
          options.map((option, index) => {
            return (
              <MenuItem key={index+1} w="100%"  display={'flex'} justifyContent='left' p="0" m="0">
                <Checkbox
                  value={option}
                  isChecked={selectedFilter.includes(option)}
                  padding={2}
                  onChange={handleSelect} 
                  w="100%"
                  h="100%"
                  p="10px"
                  m="0"
                >
                  <Box as="p" m="0" p="0" h="100%" w="100%">
                    <strong>{option}</strong>
                  </Box>
                </Checkbox>
              </MenuItem>
            )
          })}
      </MenuList>
    </Menu>
  )
}
