

import { Box, HStack, Select } from '@chakra-ui/react'
import { SORTING_CRITERIA } from '../../utils/heatmap';

const SorterHeatmap = ({ sortBy, sortVertically, setSortBy, setSortVertically }) => {

  const handleSortByCriteria = (event) => {
    const selectedCriteria = event.target.value;
    setSortBy(selectedCriteria);
  }

  const handleSortVertically = (event) => {
    const selectedVertically = event.target.value;
    setSortVertically(selectedVertically === 'Vertically');
  }

  return (
    <HStack width={"1000px"} spacing={8} alignItems="center" justifyContent="flex-start">
       <HStack width={"20%"}>
        <Box fontWeight="600" fontSize={12} pr={3} whiteSpace="nowrap">
          Order by:
        </Box>
        <Select height={'30px'} value={sortBy} onChange={handleSortByCriteria} fontSize={12}>
          {SORTING_CRITERIA.map((opt, i) => <option key={i} value={opt}>{opt}</option>)}
        </Select>
       </HStack>
        
       <HStack width={"20%"}>
          <Box fontWeight="600" fontSize={12} pr={2} whiteSpace="nowrap">
            Sort device:
          </Box>
          <Select height={'30px'} value={sortVertically ? "Vertically" : "Horizontally"} onChange={handleSortVertically} fontSize={12}>
            {["Vertically", "Horizontally"].map((opt, i) => <option key={i} value={opt}>{opt}</option>)}
          </Select>
        </HStack>
    </HStack>
  )
}

export default SorterHeatmap;
