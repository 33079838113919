import { useState } from 'react';
import Heatmap from "./HeatMap"
import CustomModal from "./CustomModal"
import FiltersHeatmap from "./filtersHeatmap"
import Loading2 from "../misc/loading-2"
import { useEffect } from "react"
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, useDisclosure, Tag, HStack, TagLabel, Stack } from "@chakra-ui/react"
import { useAlarms } from "../../hooks/useAlarms"
import Toast from '../Toast'
import SorterHeatmap from "./SorterHeatmap"
import ColorButtons from './ColorButtons'
import ColumnFilters from '../Iconsjsx/columnFilters'
import { useHeatmapContext } from '../../context/HeatmapContext'

const HeatMapView = () => {
  const [isFirstTime,  setIsFirstTime] = useState(true);
  const { onOpen, onClose, isOpen } = useDisclosure()
  const {
    isLoading,
    deviceData,
    filteredDeviceData,
    options,
    filters,
    statusValues,
    sortBy,
    sortVertically,
    fetchDeviceData,
    setSelectedDevice,
    setFilters,
    setSortBy,
    setSortVertically,
    filterCounter,
    resetFilters
  } = useHeatmapContext();
  const { messages } = useAlarms()

  useEffect(() => {
    const fetchData = async () => {
      await fetchDeviceData();
      isFirstTime && setIsFirstTime(false);
    }
    fetchData()

    // intervalos de 30 segundos
     const interval = setInterval(fetchData, 30000)
     return () => clearInterval(interval)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalFilters = filterCounter();
  const handleCardClick = (serial) => {
    const selectedData = filteredDeviceData.find(
      (item) => item.serial === serial
    )
    setSelectedDevice(selectedData)
    onOpen()
  }

  if (isLoading && isFirstTime) return <Loading2 />;

  return (
    <>
    <Accordion allowToggle>
      <AccordionItem>
        <HStack
          width={'full'}
          alignItems={'center'}
          paddingLeft={6}
          paddingRight={6}
          paddingTop={1}
          paddingBottom={1}
        >
          <Box flex='1' textAlign='left'>
            <ColorButtons
              colorValues={statusValues}
              filters={filters}
              setFilters={setFilters}
            />
          </Box>
          <AccordionButton
            as='div'
            cursor={'pointer'}
            p={0}
            _hover={{ bg: 'white' }}
            width={'fit-content'}
          >
            <HStack spacing={5} alignItems={"center"}>
              {totalFilters && (
                <Tag
                size={"sm"}
                borderRadius='full'
                variant='solid'
                colorScheme={'blue'}
              >
                <TagLabel>Filters: {totalFilters}</TagLabel>
              </Tag>
              )}
              <ColumnFilters />
            </HStack>
            
          </AccordionButton>
        </HStack>
          <AccordionPanel pb={2}>
            <Stack maxWidth="100%" overflowX="auto">
              <FiltersHeatmap
                allDevices={deviceData}
                filters={filters}
                options={options}
                setFilters={setFilters}
                resetFilters={resetFilters}
              />
              <SorterHeatmap
                sortBy={sortBy}
                sortVertically={sortVertically}
                setSortBy={setSortBy}
                setSortVertically={setSortVertically}
              />
            </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
      <Heatmap
      deviceData={filteredDeviceData}
      onSelect={handleCardClick}
      sortVertically={sortVertically}
      />
      {messages.map((message, index) => (
          <Toast
            key={index}
            status={message.type}
            message={message.error}
            title={message.title}
            showError={message.show}
            delay={index * 1000}
          />
        ))}
      <CustomModal
        onClose={onClose}
        isOpen={isOpen}
      />
    </>
  );


}

export default HeatMapView;