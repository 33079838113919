import { Spinner, Center, Container } from "@chakra-ui/react"

const Loading2 = ({ ...props }) => {
  return (
    <Container display="flex" alignItems="center" justifyContent="center" height="100vh">
      <Center h={"50vh"} {...props}>
        <Spinner size={"xl"} color={"primary.500"} />
      </Center>
  </Container>
  );
}

export default Loading2
