import { MobileNav } from './MobileNav'
import { DeskNav } from './DeskNav'
import MediaQuery from 'react-responsive'
import MSuiteMini from '../Iconsjsx/msuitemini'

// const logo = 'https://iot.msuitecloud.com/Shared/img/logo_msuite_horizontal.svg'

const NavBar = () => {
  return (
    <>
      <MediaQuery minWidth={768}>
        <DeskNav />
      </MediaQuery>
      <MediaQuery maxWidth={768}>
        <div className="nav-container mb-2">
          <nav className="navbar navbar-expand-md navbar-light bg-light p-0">
            <div className="container" id="navContainer">
              <MSuiteMini />
              <MobileNav />
            </div>
          </nav>
        </div>
      </MediaQuery>
    </>
  )
}

export default NavBar

