export const MicroChip = ({className}) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width={18}
  height={18}
  fill=""
  className={className}
>
  <path
    fill=""
    fillOpacity={0.92}
    fillRule="evenodd"
    d="M4.5 3.375c-.621 0-1.125.504-1.125 1.125v9c0 .621.504 1.125 1.125 1.125h9c.621 0 1.125-.504 1.125-1.125v-9c0-.621-.504-1.125-1.125-1.125h-9ZM2.25 4.5A2.25 2.25 0 0 1 4.5 2.25h9a2.25 2.25 0 0 1 2.25 2.25v9a2.25 2.25 0 0 1-2.25 2.25h-9a2.25 2.25 0 0 1-2.25-2.25v-9Z"
    clipRule="evenodd"
  />
  <path
    fill=""
    fillOpacity={0.92}
    fillRule="evenodd"
    d="M4.5 5.625c0-.621.504-1.125 1.125-1.125h6.75c.621 0 1.125.504 1.125 1.125v6.75c0 .621-.504 1.125-1.125 1.125h-6.75A1.125 1.125 0 0 1 4.5 12.375v-6.75Zm7.875 0h-6.75v6.75h6.75v-6.75ZM6.188 1.125c.31 0 .562.252.562.563v1.125a.563.563 0 0 1-1.125 0V1.687c0-.31.252-.562.563-.562Zm2.812 0c.31 0 .563.252.563.563v1.125a.563.563 0 0 1-1.126 0V1.687c0-.31.252-.562.563-.562Zm2.813 0c.31 0 .562.252.562.563v1.125a.562.562 0 1 1-1.125 0V1.687c0-.31.252-.562.563-.562ZM1.124 6.188c0-.311.252-.563.563-.563h1.125a.563.563 0 0 1 0 1.125H1.687a.563.563 0 0 1-.562-.563Zm13.5 0c0-.311.252-.563.563-.563h1.124a.562.562 0 1 1 0 1.125h-1.125a.562.562 0 0 1-.562-.563ZM1.125 9c0-.31.252-.563.563-.563h1.125a.563.563 0 0 1 0 1.126H1.687A.563.563 0 0 1 1.125 9Zm13.5 0c0-.31.252-.563.563-.563h1.124a.562.562 0 1 1 0 1.126h-1.125A.562.562 0 0 1 14.626 9Zm-13.5 2.813c0-.311.252-.563.563-.563h1.125a.562.562 0 1 1 0 1.125H1.687a.562.562 0 0 1-.562-.563Zm13.5 0c0-.311.252-.563.563-.563h1.124a.562.562 0 1 1 0 1.125h-1.125a.562.562 0 0 1-.562-.563Zm-8.438 2.812c.311 0 .563.252.563.563v1.124a.562.562 0 1 1-1.125 0v-1.125c0-.31.252-.562.563-.562Zm2.813 0c.31 0 .563.252.563.563v1.124a.562.562 0 1 1-1.126 0v-1.125c0-.31.252-.562.563-.562Zm2.813 0c.31 0 .562.252.562.563v1.124a.562.562 0 1 1-1.125 0v-1.125c0-.31.252-.562.563-.562Z"
    clipRule="evenodd"
  />
</svg>
)