import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@chakra-ui/react'
import { LogoutIcon } from '../Iconsjsx/logoutIcon'
import { Box } from '@chakra-ui/react'

const LogoutButton = ({label}) => {
  const { logout } = useAuth0()
  return (
  <Box
  display="flex"
  justifyContent="flex-start"
  alignItems="center"
  marginTop="2"
>
  <Button
    className="bg-white  border-1-solid border-gray-700 text-gray-700"
    onClick={() =>
        logout({ 
        logoutParams: {
          returnTo: window.location.origin
        }
      })}
    display="flex"
    marginBottom={4}
    alignItems="center"
    border={'rounded-md'}
  >
    <LogoutIcon marginRight="4" /> 
  {label &&  
    <Box as='span' fontSize={14} marginLeft={4}
     >{label}</Box>}
  </Button>
</Box>
  )
}

export default LogoutButton
