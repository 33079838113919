import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container } from 'reactstrap'
import axios from 'axios'
import BarChart from '../components/OLDgraph'
import Loading2 from '../components/misc/loading-2'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { usePermissionState } from '../context/PermissionContext'

const Production = () => {
  const [data, setData] = useState([])
  const URL_BACK = process.env.REACT_APP_URL_BACK
  const { userPermission } = usePermissionState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(URL_BACK + '/api/device-data-graph')
        .then((response) => {
          setData(response.data)
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
          setIsLoading(false)
        })
    }

    fetchData()

    const interval = setInterval(() => {
      fetchData()
    }, 60000)

    return () => clearInterval(interval)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission])

  function compareDevices(a, b) {
    const deviceA = a.Device.toUpperCase()
    const deviceB = b.Device.toUpperCase()

    if (deviceA < deviceB) {
      return -1
    }
    if (deviceA > deviceB) {
      return 1
    }
    return 0
  }

  // Ordenar el arreglo
  data.sort(compareDevices)

  data.forEach((item) => {
    item.datoslinea1 = item.datoslinea1.reverse()
    item.datoslinea2 = item.datoslinea2.reverse()
    item.datoslinea3 = item.datoslinea3.reverse()
    item.datoslinea4 = item.datoslinea4.reverse()
  })

  if (isLoading || userPermission === null) {
    return (
      <Container>
        <Loading2 />
      </Container>
    )
  }

  if (
    userPermission === false ||
    (userPermission !== 'Assist' && userPermission !== 'Super')
  ) {
    return (
      <Container>
        <h1 size={30} weight="bold">
          No tienes permisos para ver esta página
        </h1>
      </Container>
    )
  }

  return (
    <Container>
      {data.map((item, index) => (
        <div key={index}>
          <h1>{item.Device}</h1>
          <BarChart
            datosLinea1={item.datoslinea1}
            datosLinea2={item.datoslinea2}
            datosLinea3={item.datoslinea3}
            datosLinea4={item.datoslinea4}
          />
        </div>
      ))}
    </Container>
  )
}

export default withAuthenticationRequired(Production)
