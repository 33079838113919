const Gear = ({classNames}) => (
  <svg className={classNames} viewBox="0 0 18 18">
    <g clipPath="url(#clip0_2136_175555)">
      <path
        fill="transparent"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity={0.64}
        strokeWidth={1.4}
        d="M8.374 6.377a2 2 0 1 0-.394 3.98 2 2 0 0 0 .394-3.98Zm4.815 1.99a4.804 4.804 0 0 1-.048.65l1.413 1.108a.337.337 0 0 1 .077.43l-1.337 2.312a.338.338 0 0 1-.41.144l-1.404-.565a.504.504 0 0 0-.474.054 5.13 5.13 0 0 1-.673.392.497.497 0 0 0-.275.38l-.21 1.496a.346.346 0 0 1-.334.287H6.84a.347.347 0 0 1-.334-.277l-.21-1.495a.502.502 0 0 0-.28-.382 4.839 4.839 0 0 1-.672-.393.502.502 0 0 0-.472-.053l-1.403.565a.338.338 0 0 1-.41-.143l-1.337-2.313a.338.338 0 0 1 .077-.43l1.194-.937a.502.502 0 0 0 .187-.44 4.259 4.259 0 0 1 0-.777.5.5 0 0 0-.19-.435l-1.193-.938a.338.338 0 0 1-.074-.427L3.06 3.867a.338.338 0 0 1 .41-.143l1.404.565a.503.503 0 0 0 .474-.055 5.14 5.14 0 0 1 .673-.392.498.498 0 0 0 .275-.38l.21-1.496a.346.346 0 0 1 .334-.286h2.674a.347.347 0 0 1 .334.277l.21 1.494a.502.502 0 0 0 .28.382c.235.113.459.244.671.393a.5.5 0 0 0 .473.053l1.402-.564a.338.338 0 0 1 .411.143l1.337 2.312a.338.338 0 0 1-.077.43l-1.194.938a.501.501 0 0 0-.189.44c.01.129.017.259.017.39Z"
      />
    </g>
  </svg>
)
export default Gear
