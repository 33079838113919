import React, { useMemo, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useHeatmapContext } from "../../context/HeatmapContext";
import { Text, Box, IconButton } from "@chakra-ui/react"
import moment from "moment/moment"
import { FaRegEye } from "react-icons/fa";
import { useAuth0 } from '@auth0/auth0-react'
import { postProtectedResource } from '../../auth/auth-fetch'

const tableProps = {
  muiTableBodyCellProps: {
    align: "center",
    sx: {
      height: "10px",
      textAlign: "center",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      borderRight: "1px solid #e0e0e0",
    },
  },
  muiTableHeadCellProps: {
    sx: {
      height: "40px",
      borderRight: "1px solid #e0e0e0",
      fontSize: "12px",
      color: "rgb(74, 85, 104)",
    },
  }
}

const initialState = {
  showColumnFilters: false,
  sorting: [{ id: "created_at", desc: true }],
};

const AlarmViewerTable = () => {
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 20});
  const { fetchDeviceData, unseenAlarms } = useHeatmapContext();
  const { user, getAccessTokenSilently } = useAuth0()
  const idAuth0 = user?.sub;
  const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE
  const URL_BACK = process.env.REACT_APP_URL_BACK

  const handleSeen = async (alarmId, serial) => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: AUDIENCE,
        },
      })
      const urlFetch = `${URL_BACK}/api/devices-assistance-seen`
      const body = {
        idAuth0,
        alarm_id: alarmId,
        serial
      }
      await postProtectedResource(urlFetch, token, body)
      fetchDeviceData();
    } catch (error) {
      console.error(error)
    }
  }

  const columns = useMemo(
    () => [
      {
        header: "DATE",
        accessorKey: "created_at",
        Cell: ({ renderedCellValue }) => (
          <Text m={0}>{moment(renderedCellValue).format("YYYY-MM-DD HH:mm")}</Text>
        ),
        minSize: 50,
        autoSize: true,
        ...tableProps,
      },
      {
        header: "DEVICE",
        accessorKey: "serial",
        Cell: ({ renderedCellValue }) => (
          <Box justifyContent="center" alignItems="center" width="100%">
            <Box bg="#BEE3F8" borderRadius="8px" textAlign="center" padding="2px" color="#2A4365">
              <Text p={0} m={0}>{renderedCellValue}</Text>
            </Box>
          </Box>
        ),
        autoSize: true,
        ...tableProps,
      },
      {
        header: "LOCATION",
        accessorKey: "location",
        minSize: 50,
        autoSize: true,
        ...tableProps,
      },
      {
        header: "ALARM NAME",
        accessorKey: "alarm_name",
        minSize: 350,
        ...tableProps,
      },
      {
        header: "SEVERITY",
        accessorKey: "severity",
        Cell: ({ renderedCellValue }) => (
          <Box justifyContent="center" alignItems="center" width="100%">
            <Box
              bg={
                renderedCellValue === 1
                  ? "#F7DCE2"
                  : renderedCellValue === 2
                  ? "#F9E6CE"
                  : "#FAF6D3"
              }
              borderRadius="8px"
              textAlign="center"
              padding="2px"
              color="#2A4365"
            >
              <Text p={0} m={0}>{renderedCellValue===1?'HIGH': renderedCellValue===2? 'MEDIUM':'LOW'}</Text>
            </Box>
          </Box>
        ),
        minSize: 50,
        ...tableProps,
      },
      {
        header: "STATUS",
        accessorKey: "status",
        // accessorFn: (dataRow) => dataRow?.closed_at ? "CLOSED" : "OPEN",
        Cell: ({ renderedCellValue }) => (
          <Box justifyContent="center" alignItems="center" width="100%">
            <Box bg={renderedCellValue? "#FAD9C1" : "#B0EFE3"} borderRadius="8px" textAlign="center" padding="2px" color="#2A4365">
              <Text p={0} m={0}>{renderedCellValue?'CLOSED':'OPEN'}</Text>
            </Box>
          </Box>
        ),
        filterVariant: "select",
        filterSelectOptions: ["Open", "Closed"],
        minSize: 50,
        autoSize: true,
        ...tableProps,
      },
      {
        header: "VIEW",
        accessorKey: "viewer",
        Cell: ({ row }) => {
          const alarmId = row.original.id;
          const serial = row.original.serial; 
          return (
            <IconButton
              onClick={() => handleSeen(alarmId, serial)}
              variant="transparent"
              aria-label="Done"
              color="blue.500"
              icon={<FaRegEye />}
            />
          );
        },
        ...tableProps,
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: unseenAlarms,
    initialState,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableMultiSort: false,
    enableSorting: true,
    enableColumnFilters: false,
    muiPaginationProps: {
      color: "primary",
      shape: "rounded",
      showRowsPerPage: false,
      variant: "outlined",
    },
    state:{
      pagination,
    },
    onPaginationChange: setPagination,
    paginationDisplayMode: "pages",
    rowCount: unseenAlarms.length,
    columnVirtualizerOptions: { overscan: 4 },
    // enableColumnPinning: true,
    enableColumnResizing: true,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: "0",
      },
    },
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
        {
          backgroundColor: "#F7FAFC",
        },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
        {
          backgroundColor: "#F7FAFC",
        },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
        {
          backgroundColor: "#FFFFFF",
        },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
        {
          backgroundColor: "#F7FAFC",
        },
      }),
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MaterialReactTable table={table} />
    </LocalizationProvider>
  );
};

export default AlarmViewerTable