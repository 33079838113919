// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Estilo para el tooltip */
.d3Tip {
  z-index: 9999;
  position: absolute;
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
}

/* Posicionamiento adicional si es necesario */
.d3Tip.custom {
  transform: translate(10px, -10px); /* Desplazamiento adicional */
}

.label {
  font-size: 10px;
}

@keyframes blink {
  0%, 100% {
    fill: var(--originalColor);
  }
  50% {
    fill: var(--darkColor);
  }
}

.blinking {
  animation: blink 1s infinite;
}
`, "",{"version":3,"sources":["webpack://./src/components/heatmap/heatmap.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;EACE,aAAa;EACb,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA,8CAA8C;AAC9C;EACE,iCAAiC,EAAE,6BAA6B;AAClE;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,0BAA0B;EAC5B;EACA;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":["/* Estilo para el tooltip */\r\n.d3Tip {\r\n  z-index: 9999;\r\n  position: absolute;\r\n  line-height: 1;\r\n  font-weight: bold;\r\n  padding: 12px;\r\n  background: rgba(0, 0, 0, 0.8);\r\n  color: #fff;\r\n  border-radius: 2px;\r\n  pointer-events: none;\r\n}\r\n\r\n/* Posicionamiento adicional si es necesario */\r\n.d3Tip.custom {\r\n  transform: translate(10px, -10px); /* Desplazamiento adicional */\r\n}\r\n\r\n.label {\r\n  font-size: 10px;\r\n}\r\n\r\n@keyframes blink {\r\n  0%, 100% {\r\n    fill: var(--originalColor);\r\n  }\r\n  50% {\r\n    fill: var(--darkColor);\r\n  }\r\n}\r\n\r\n.blinking {\r\n  animation: blink 1s infinite;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
