import { withAuthenticationRequired } from '@auth0/auth0-react'
import  Battery  from "../components/battery/battery"
import { Container, Box } from "@chakra-ui/react"
import Loading2 from '../components/misc/loading-2'
import { usePermissionState } from '../context/PermissionContext'
import { useCamBattery } from "../hooks/useCamBattery"


const Diagnostic = () => {
  const { userPermission } = usePermissionState()
  const { isLoading, error } = useCamBattery()


  if (isLoading || userPermission === null) {
    return (
      <Container display="flex" alignItems="center" justifyContent="center" height="100vh">
        <Loading2 />
      </Container>
    )
  }

  if (error){
    return (
      <Container display="flex" alignItems="center" justifyContent="center" height="100vh">
        <h1 size={30} weight="bold">
          {error}
        </h1>
    </Container>
    )
  }

  if (!userPermission || userPermission.camera !== true){
    return (
      <Container display="flex" alignItems="center" justifyContent="center" height="100vh">
        <h1 size={30} weight="bold">
          No tienes permisos para ver esta página
        </h1>
      </Container>
    )
  }

  return (
    <Box className="container" marginTop={{base: "10px", md: "90px", lg: "100px" }} >
      <Battery />
    </Box>
    )
}

export default withAuthenticationRequired(Diagnostic)