import { useAuth0 } from '@auth0/auth0-react'
import { useState, useEffect } from 'react'
import { postUnprotectedResource, postUnprotectedImage } from '../auth/auth-fetch'

export const useCamera = () => {
    const { user } = useAuth0()
    const [cameras, setCameras] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const URL_MS_CAMERAS = process.env.REACT_APP_URL_MS_CAMERAS



    useEffect(() => {
        (async () => {
            try {
                const payload = JSON.stringify({
                    "user_id": user?.sub.replace('auth0|', '')
                })
                const urlFetchLastEvents = `${URL_MS_CAMERAS}/cameras/last-events`
                const urlFetchEventImage = `${URL_MS_CAMERAS}/cameras/image`

                const data = await postUnprotectedResource(urlFetchLastEvents, payload)
                const arrayData = Object.entries(data.data).map(([key, value]) => {
                    return { key, ...value };
                });
                console.log(arrayData)

                for (const item of arrayData) {
                    if (!item.id) continue
                    const photoResponse = await postUnprotectedImage(urlFetchEventImage, JSON.stringify({
                        "event_id": item.id
                    }));
                    console.log(photoResponse);
                
                    const blob = new Blob([photoResponse.data], { type: 'image/jpeg' });
                    const url = URL.createObjectURL(blob);
                    item.photoUrl = url;
                }
                
                setCameras(arrayData);
                setLoading(false);

            } catch (error) {
                console.error(error);
                setError(error.response.data.error)
                setLoading(false)
            }
        })();
    }, [])

    return { cameras, loading, error }
}
