import React, { Fragment } from "react";
import {withAuthenticationRequired} from '@auth0/auth0-react'

import Hero from "../components/misc/hero";

const Home = () => {


  return (
  <Fragment>
    <Hero />
  </Fragment>
)
}

export default withAuthenticationRequired(Home);
