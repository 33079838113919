import { useEffect, useState } from 'react'
import { Box, Button, HStack } from '@chakra-ui/react'
import SearchBar from '../shared/SearchBar'
import { FilterDropdown } from './filterDropdown'

const buildDevices = (devices = []) => {
  return [...new Set(devices.map(d => d.serial).filter(d => !!d))];
}

const FiltersHeatmap = ({
  allDevices,
  filters,
  options,
  setFilters,
  resetFilters,
}) => {
  const [devices, setDevices] = useState([])
  const [models, setModels] = useState([])
  const [assetsOwners, setAssetsOwners] = useState([])
  const [operatorCompanies, setOperatorCompanies] = useState([])
  const [serviceCompanies, setServiceCompanies] = useState([])
  const setearDevices = (selectedDevices) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      devices: selectedDevices,
    }))
  }

  useEffect(() => {
    setModels(options.models)
    setDevices(buildDevices(allDevices))
    setAssetsOwners(options.assetsOwners)
    setOperatorCompanies(options.operatorCompanies)
    setServiceCompanies(options.serviceCompanies)
  }, [allDevices]);

  const handleServiceSelect = (event) => {
    const checkedServName = event.target.value
    const isChecked = event.target.checked

    // select all
    if (checkedServName === "all") {
      setFilters((prevFilters) => {
        const newFilters = {
          ...prevFilters,
          services: isChecked ? JSON.parse(JSON.stringify(serviceCompanies)) : []
        }
        return newFilters
      });
      return;
    }

    setFilters((prevFilters) => {
      // Crear una copia del array de filtros existente
      const newLocFilters = [...prevFilters.services]
      // Verificar si la ubicación ya está en los filtros
      const servicesIndex = newLocFilters.indexOf(checkedServName)
      if (servicesIndex !== -1) {
        // Si está presente, eliminar la ubicación de los filtros
        newLocFilters.splice(servicesIndex, 1)
      } else {
        // Si no está presente, agregar la ubicación a los filtros
        newLocFilters.push(checkedServName)
      }
      const newFilters = { ...prevFilters, services: newLocFilters }

      return newFilters
    })
  }

  const handleModelSelect = (event) => {  
    const checkedModel = event.target.value;
    const isChecked = event.target.checked;
    // select all

    if (checkedModel === "all") {
      setFilters((prevFilters) => {
        const newFilters = {
          ...prevFilters,
          models: isChecked ? JSON.parse(JSON.stringify(models)) : []
        }
        return newFilters
      });
      return;
    }

    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters }
      const modelIndex = newFilters.models.indexOf(checkedModel)
  
      if (modelIndex !== -1) {
        newFilters.models.splice(modelIndex, 1)
      } else {
        newFilters.models.push(checkedModel)
      }
  
      return newFilters
    })
  }

  const handleAssetsOwnerSelect = (event) => {
    const owner = event.target.value;
    const isChecked = event.target.checked;

    // select all
    if (owner === "all") {
      setFilters((prevFilters) => {
        const newFilters = {
          ...prevFilters,
          owners: isChecked ? JSON.parse(JSON.stringify(assetsOwners)) : []
        }
        return newFilters
      });
      return;
    }

    // Actualizar filters
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters }
      if (isChecked) {
        if (!newFilters.owners?.includes(owner)) {
          newFilters.owners.push(owner)
        }
      } else {
        const index = newFilters.owners.indexOf(owner)
        if (index !== -1) {
          newFilters.owners.splice(index, 1)
        }
      }
      return newFilters
    })
  }

  const handleOperatorCompanySelect = (event) => {
    const operatorCompany = event.target.value;
    const isChecked = event.target.checked;

    // select all
    if (operatorCompany === "all") {
      setFilters((prevFilters) => {
        const newFilters = {
          ...prevFilters,
          operators: isChecked ? JSON.parse(JSON.stringify(operatorCompanies)) : []
        }
        return newFilters
      });
      return;
    }

    // Actualizar filters
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters }
      if (isChecked) {
        if (!newFilters.operators?.includes(operatorCompany)) {
          newFilters.operators.push(operatorCompany)
        }
      } else {
        const index = newFilters.operators.indexOf(operatorCompany)
        if (index !== -1) {
          newFilters.operators.splice(index, 1)
        }
      }
      return newFilters
    })
  }

  return (
    <HStack width={"1000px"} spacing={8} alignItems="center" justifyContent="flex-start" mb={1}>
      <HStack width={"20%"} position="relative" overflow="visible">
        <Box fontWeight="600" fontSize={12} pr={5} whiteSpace="nowrap">Devices:</Box>
        <SearchBar
          data={devices}
          selectedItems={filters.devices}
          setSelectedItems={setearDevices}
        />
      </HStack>
      <HStack width={"75%"}>
      <FilterDropdown
        title={'Model'}
        options={models}
        selectedFilter={filters.models}
        handleSelect={handleModelSelect}
      />
      <FilterDropdown
        title={'Asset Owner'}
        options={assetsOwners}
        selectedFilter={filters.owners}
        handleSelect={handleAssetsOwnerSelect}
      />
      {serviceCompanies.length && (
        <FilterDropdown
        title={'Service Company'}
        options={serviceCompanies}
        selectedFilter={filters.services}
        handleSelect={handleServiceSelect}
        />
      )}
      {operatorCompanies.length && 
        <FilterDropdown
          title={'Operator Company'}
          options={operatorCompanies}
          selectedFilter={filters.operators}
          handleSelect={handleOperatorCompanySelect}
        />
      }
      <Button height={"30px"} onClick={resetFilters} fontSize={12}>Clear filters</Button>
      </HStack>
    </HStack>
  )
}

export default FiltersHeatmap
